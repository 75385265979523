import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  }
}));

const FAQS = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          color="textPrimary"
        >
          Frequently asked questions (FAQs)
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Grid
          container
          spacing={3}
          component="dl"
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Typography
              variant="h3"
              color="secondary"
            >
              API KEY
            </Typography>

            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  How to get API Key?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Register Free (without providing Card Details) with an email and password.
                </Typography>
              </dd>
            </Box>

            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  Can I publicly expose my API Key by embedding it in some client-side code?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Yes. Many people choose this route due to faster response times and no server-side
                  code to handle API queries. However, you need to take appropriate measures to limit
                  your API Key so it cannot be misused by others.
                </Typography>
              </dd>
            </Box>

            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  Can I get my data in XML (or some other data format)?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Not right now. It's all in JSON. We do not have plans to use other formats.
                </Typography>
              </dd>
            </Box>

            <Box my={3}>
              <spacing />
            </Box>
            <Typography
              variant="h3"
              color="secondary"
            >
              PRICING & USAGE
            </Typography>

            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  How to Pay for APITier API Key?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  API providers offer subscription plans tailored to the needs of individual developers, startups, and small-to-medium enterprises (SMEs). These subscription plans often provide access to a range of features and usage limits, allowing you to select the plan that best fits your requirements.
                 
                 </Typography>
              </dd>
            </Box>

            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  Are my credit card payments safe?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Yes, all payment details are sent directly to Stripe. We never see any of this information. Stripe has been audited by a PCI-certified auditor and is certified to PCI Service Provider Level 1. This is the most stringent level of certification available.
                  Stripe link - <a href="https://stripe.com" target="_blank" rel="noopener" >https://stripe.com</a>
                </Typography>
              </dd>
            </Box>
            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  Can I cancel my subscription at anytime?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  You can cancel your subscription at any time with our service. We understand that your needs may change, and we want to ensure you have the flexibility to adjust your plan as necessary.
                </Typography>
              </dd>
            </Box>
            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  How usage is calculated?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Each API call on the APITier platform count as the use of 1 API credit.<br />
                  Example -<br />
                  1. Single Find By Postcode API call, count as 1 credit use<br />
                  2. Single Address Lookup API call, count as 1 credit use<br />
                  3. Single Validate Email API call, count as 1 credit use<br />
                </Typography>
              </dd>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Typography
              variant="h3"
              color="secondary"
            >
              DEVELOPMENT
            </Typography>
            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  What Is APITier?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  APITier is an API platform that provides a set of Verification, UK PostCode, India Pincode,
                  WEB Conversion, Data Conversion, Image Conversion, and BarCode APIs.
                </Typography>
              </dd>
            </Box>
            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  How to integrate APITier API?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  We provide free boilerplate code for all major languages for easy integration into the apps.
                </Typography>
              </dd>
            </Box>

            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  Where does the data come from?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  We cross-reference Ordnance Survey's and Royal Mail (Postcode Address File) complete list of UK postcodes (code point open) with the latest property sales, council tax register, companies house data,
                  and over twenty other data sources to create a near-complete address database.
                </Typography>
              </dd>
            </Box>


            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  Can I save or cache the addresses I receive?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Yes, addresses are public data.
                </Typography>
              </dd>
            </Box>
            <Box my={3}>
              <spacing />
            </Box>
            <Typography
              variant="h3"
              color="secondary"
            >
              SUPPORT
            </Typography>
            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  Are you providing support?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Yes, we offer email support for all our customers &amp;
                  even online meetings for our extended license customers.
                </Typography>
              </dd>
            </Box>
            <Box mt={6}>
              <dt>
                <Typography
                  variant="h3"
                  color="textPrimary"
                >
                  What's the difference between Basic and Priority support?
                </Typography>
              </dt>
              <dd>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  Priority support is available 24-hours a day and can give advice on your code issues.
                  Basic support will only provide advice with API issues or responses from our servers.
                </Typography>
              </dd>
            </Box>


          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

FAQS.propTypes = {
  className: PropTypes.string
};

export default FAQS;
