import React from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { dashboardApiConfig } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import SideBarMenu from '../SideBarMenu';
import Header from '../Header';
import GetStarted from './GetStarted';
import Overview from '../Overview'

const data = [
  { title:'Validate Phone', desc:"Validate Phone", api:[{id: 0, url:  dashboardApiConfig.dashboard_validate_phone_api_url+'/validate?' }]}
  ]

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ValidatePhoneView = () => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <Page
      className={classes.root}
      title="Validate Phone API | APITier"
    >
      <Container maxWidth={false}>
        <Header />

        <Grid
          container
          spacing={3}
          style={{paddingTop: 20}}
        >
           <Grid
        item
        lg={12}
        xs={12}
      >
         <Overview/>

      </Grid>
          <Grid
            item
            lg={9}
            md={12}
            xl={9}
            xs={12}
          >
            <GetStarted
              data={data}
              user={user}
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={6}
            xl={3}
            xs={12}
          >
            <SideBarMenu user={user}/>
          </Grid>

        </Grid>
      </Container>
    </Page>
  );
};

export default ValidatePhoneView;

