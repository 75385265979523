import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  DollarSign as DollarSignIcon,
  CreditCard as CreditCardIcon,
  FastForward as FastForwardIcon,
  Activity as ActivityIcon,
  Clock as ClockIcon,
  GitMerge as GitMergeIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 78,
    paddingBottom: 78
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const Benefits = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="h4"
          color="secondary"
          align="center"
        >
          WHY USE API TIER APIs?
        </Typography>
        <Box my={3}>
          <spacing />
        </Box>
        <Typography
          variant="h2"
          align="center"
          color="textPrimary"
        >
         What are benefits of using APITier APIs? 
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <DollarSignIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Pay Per Request
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    You pay only for the queries that you run. You are charged for each successful request.                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <CreditCardIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Pay As You Go Pricing Plan
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    Simple, easy to understand Pay As You Go pricing.
                    Affordable plans - suitable for startups to large enterprises.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <FastForwardIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Start querying instantly
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    The most up-to-date & accurate data.
                    You can quickly query your data without having to set up or
                    manage any servers or data warehouses.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <ActivityIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Performance at any scale
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    APIs are backed by a robust highly scalable cloud infrastructure. Provide end-users with the lowest latency for API calls.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <ClockIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Efficient Application development
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    Simple API design allows you to quickly iterate, test, and release new versions.                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <GitMergeIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Easy To Integrate
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    Simple, self-explanatory API will get you up and running in under 10 minutes.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

Benefits.propTypes = {
  className: PropTypes.string
};

export default Benefits;