import mock from 'src/utils/mock';

const messages = [
  {
    id: '5e8883f1b51cc1956a5a1ec0',
    title: 'Validate Email API',
    type: 'verification_api',
    description : 'Validate email addresses quickly and accurately with our reliable Email Validate API. Our powerful API tool instantly verifies email addresses to protect you from bounces, spam traps, and disposable domains. Get started for free today!',
    link: '/api-catalogue/validate-email-api'
  },
  {
    id: '5e8883f1b51cc1956a5a1ec1',
    title: 'Validate Phone API',
    type: 'verification_api',
    description : 'Instantly verify & validate telephone numbers with Phone Validator. Our comprehensive platform checks phone numbers against multiple global databases, helping you to identify valid and invalid contacts quickly and accurately. Get started today for reliable results every time.',
    link: '/api-catalogue/validate-phone-api'
  },
  {
    id: '5e8883f1b51cc1956a5a1ec2',
    title: 'VAT Number Validation API',
    type: 'verification_api',
    description : 'Checking and validating VAT numbers is an important part of any business. It helps to ensure that you are dealing with legitimate companies and not fraudsters. To do this, you need to find reliable online resources that can provide accurate information about VAT numbers.',
    link: '/api-catalogue/validate-vat-api'
  },
  {
    id: '5e8883f1b51cc1956a5a1ec3',
    title: 'User Agent Parser API',
    type: 'verification_api',
    description : 'Instantly identify the browsers, operating systems, and devices used by your users to ensure your web applications are optimized for maximum compatibility and performance.',
    link: '/api-catalogue/validate-useragent-api'
  },

  {
    id: '5e8883f7ed1486d665d8be1f',
    title: 'PostCode Lookup API',
    type: 'uk_postcode_api',
    description : 'Get the most accurate and up-to-date postcode data with our industry-leading Postcode Data Platform. We provide comprehensive coverage, instant access, and reliable data to enhance your customer experience. Our platform is trusted by businesses worldwide for its accuracy',
    link: '/api-catalogue/postcode-lookup-api'
  },
  {
    id: '5e8883f7ed1486d665d8be1f',
    title: 'Address AutoSuggest API',
    type: 'uk_postcode_api',
    description : 'Get accurate and real-time address suggestions with our Address AutoSuggest API. Our API is designed to provide quick and reliable address autocomplete for any website or application. With our simple integration, you can give your users a smoother checkout experience and reduce',
    link: '/api-catalogue/address-autosuggest-api'
  },
  {
    id: '5e8883f7ed1486d665d8be1f',
    title: 'Address Lookup API',
    type: 'uk_postcode_api',
    description : 'Get the most accurate address data with our free Address Lookup API. Our powerful, reliable, and easy-to-use API provides businesses with comprehensive address validation and verification capabilities. Get started today with free access to our secure and fast API.',
    link: '/api-catalogue/address-lookup-api'
  },
  

  {
    id: '5e8883f7ed1486d665d8be1e',
    title: 'ScreenShot API',
    type: 'web_conversion_api',
    description : 'Need to take screenshots quickly and easily? Our Screenshot API makes it simple. Get detailed screenshots of web pages, capture website elements, and track changes over time. Get the most out of your screenshot needs with our easy-to-use API.',
    link: '/api-catalogue/screenshot-api'
  },
  {
    id: '5e8883f7ed1486d665d8be1f',
    title: 'WEB To PDF API',
    type: 'web_conversion_api',
    description : 'Capture screenshots of any web page or PDF document in seconds with our simple online tool. Easily share screenshots for online meetings, presentations, or education purposes with our easy-to-use interface. Get started now and quickly take screenshots with ease.',
    link: '/api-catalogue/web-to-pdf-api'
  },
  {
    id: '5e8883f7ed1486d665d8be1g',
    title: 'WEB To TEXT API',
    type: 'web_conversion_api',
    description : 'Web Content Extractor is a powerful web scraping tool that helps you extract text, images, and other data from websites and HTML documents. Get the content you want quickly and easily, without the need for manual copy-and-paste.',
    link: '/api-catalogue/web-to-text-api'
  },
  {
    id: '5e8883f7ed1486d665d8be1h',
    title: 'WebScrape API',
    type: 'web_conversion_api',
    description : 'Get the most out of your data with WebScrape API. Our cloud-based platform helps you easily extract, transform, and analyze data from any website or web page. Get started with our easy-to-use interface and sophisticated extraction tool',
    link: 'api-catalogue/web-scrape-api'
  },
  
  {
    id: '5e8883fca0e8612044248eci',
    title: 'DATA Conversion API',
    type: 'data_conversion_api',
    description : 'Transform any data format into the format you need with our expert data conversion services. Our team of experts can convert your data from one format to another quickly, accurately, and securely. Get started now and access the highest quality data conversion services.',
    link: '/api-catalogue/data-conversion-api'
  },
  
  {
    id: '5e8883fca0e8612044248ece',
    title: 'INDIA PinCode API',
    type: 'in_pincode_api',
    description : 'Find accurate pincodes in India easily with our free Pincode API. Quickly get the postal code of any location in India with a few simple clicks. Save time and resources by using our reliable, updated pincode database.',
    link: '/api-catalogue/india-pincode-api'
  },
  
  {
    id: '5e8883fca0e8612044248ecd',
    title: 'BarCode Generator API',
    type: 'barcode_api',
    description : 'BarCode Generator API is the ultimate solution for your business barcode needs. Our easy-to-use platform allows you to quickly generate industry-standard barcodes that are perfect for printing, scanning, and managing inventory. Try it now and see how',
    link: '/api-catalogue/barcode-api'
  },
  {
    id: '5e8883fca0e8612044248ecb',
    title: 'Generate QR Code API',
    type: 'barcode_api',
    description : 'Generate high-quality, secure QR Codes quickly and easily with our generate QR Code API. Our easy-to-use platform lets you create custom codes with your own branding, data, and design. Get started today and get the most out of',
    link: '/api-catalogue/qrcode-api'
  },

];

mock.onGet('/api/messages').reply(200, { messages });
