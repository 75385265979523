import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Products from './Products'
const useStyles = makeStyles(() => ({
  root: {}
}));

const ProductListView = () => {
  const classes = useStyles();

  return (

    <Page
      className={classes.root}
      title="Restful APIs for Java and Python: The Best API Platform | APITier"
      description="Enrich the user experience, and expedite development with APITiers suite of RESTful APIs. Our comprehensive API catalogue offers postcode lookup, address lookup, generate barcode, qr code generator, email validate, phone validator and many mode APIs for Free."
      canonicalURL="https://www.apitier.com/api-products"
    >

      <Products />

    </Page>
  );
};

export default ProductListView;
