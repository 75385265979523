import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
// import Hero from './Hero';
import Benefits from './Benefits';
import SimpleSteps from './SimpleSteps';
import CTA from './CTA';
import FAQS from './FAQS';
import Recommendation from './Recommendation'
import BusinessBenefits from './BusinessBenefits'
import FreeTool from './FreeTool';
import Catalogue from './Catalogue'
import Hero from './Hero'
import PricingView from '../../dashboard/BusinessPricing/PricingView'
import Companies from './Companies'

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Highly Scalable APIs | APITier"
      canonicalURL="https://www.apitier.com"
      description = "APITier provides a suite of Verification, WEB Conversion, Data Conversion, and UK PostCode APIs. These are core building blocks to help you build day-to-day address validation, email verification, VAT Validation, User Agent Validation. This highly scalable platform helps businesses maximize their ROI on development initiatives. Get your free trial API Key today!"
    >
      <Hero />
      <Companies />
      <FreeTool />
      <Catalogue />
      <SimpleSteps />
      <PricingView />
      <Benefits />
      <BusinessBenefits />
      <FAQS />
      <CTA />
      <Recommendation />
    </Page> 
  );
};

export default HomeView;
