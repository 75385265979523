import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Container,
  Typography,
  makeStyles,
  Box,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  logoBox: {
    width: '170px',
    height: '150px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    margin: theme.spacing(2),
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow: '0 0 12px rgba(0, 0, 0, 0.3)',
    },
    cursor: 'pointer',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
 
  '@media (min-width: 1200px)': {
    logoContainer: {
      justifyContent: 'flex-start',
    },
  },
}));

const Companies = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="h1"
          color="textPrimary"
          align="center"
        >
          Trusted By Companies
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Box className={classes.logoContainer}>
          <Box className={classes.logoBox} onClick={() => window.open('https://mailtrap.io/', '_blank')}>
            <a href="https://mailtrap.io/" target="_blank">
              <img src="/static/companies/mailtrap-logo.svg" alt="mailtrap" className={classes.logo} />
            </a>
          </Box>

          <Box className={classes.logoBox} onClick={() => window.open('https://flexgpt.io', '_blank')}>
            <a href="https://flexgpt.io" target="_blank">
               <img src="/static/companies/flexgpt-logo.png" alt="flexgpt" className={classes.logo} />
            </a>
          </Box>

          <Box className={classes.logoBox} onClick={() => window.open('https://proton.me/', '_blank')}>
             <a href="https://proton.me/" target="_blank">
              <img src="/static/companies/protonag-logo.svg" alt="protonag" className={classes.logo} />
            </a>
           </Box>

           <Box className={classes.logoBox} onClick={() => window.open('https://mylocalsurgery.co.uk/', '_blank')}>
             <a href="https://mylocalsurgery.co.uk/" target="_blank">
              <img src="/static/companies/m.png" alt="mylocalsurgery" className={classes.logo} />
             </a>
           </Box>

           <Box className={classes.logoBox} onClick={() => window.open('https://www.fujitsu.com/', '_blank')}>
               <a href="https://www.fujitsu.com/" target="_blank">
               <img src="/static/companies/fujitsu-logo.png" alt="fujitsu" className={classes.logo} />
           </a>
         </Box>

           <Box className={classes.logoBox} onClick={() => window.open('https://www.orange.fr/', '_blank')}>
           <a href="https://www.orange.fr/" target="_blank">
             <img src="/static/companies/orange-logo.png" alt="orange" className={classes.logo} />
           </a>
           </Box>
        </Box>
      </Container>
    </div>
  );
};

Companies.propTypes = {
  className: PropTypes.string
};

export default Companies;

