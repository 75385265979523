import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Card,
    CardHeader,
    makeStyles,
    hexToRgb
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        margin: theme.spacing(2),
        color: hexToRgb("#546e7a"),
        boxShadow: "0 4px 7px 0 rgba(225 225 234)",
        transition: "0.3s"
    },
}));

const Overview = ({ className, ...rest }) => {
    const classes = useStyles();

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <CardHeader
                className={classes.current}
                subheader="“Validate Phone“ API Validate national and international phone numbers and get location information about phone number.
                It's simple REST API to Verify and Validate single phone or bulk phone numbers."
            />
        </Card>
    );
};

Overview.propTypes = {
    className: PropTypes.string
};

export default Overview;
