import React from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  hexToRgb,
  Link
} from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(3)
  },
  cardHeader: {
    color: hexToRgb('#787878'),
    padding: 10,
  },
  content: {
    listStyle: 'none', textDecoration: 'none',
    padding: 10
  },

  isactive: {
    color: hexToRgb('#787878'),
    fontWeight: 500,
    padding: 5
  },
  active: {
    color: hexToRgb('#5850EC'),
    fontWeight: 500
  },
  contentli:{
    '&:visited':{
      borderLeft:'1px solid gray'
    }
  }
}));
const SideBarMenu = ({ user }) => {

  const classes = useStyles();

  const history = useHistory();
  let loc = useLocation();

  function pricingButtonClick() {
    // history.push("/api/verification/validate-phone/pricing");
    history.push("/api/pricing-plans");
  }
  function securityButtonClick() {
    history.push("/api/verification/validate-phone/security");
  }
  function subscriptionButtonClick() {
    history.push("/api/verification/validate-phone/subscription");
  }
  function apiUsageButtonClick() {
    history.push("/api/verification/validate-phone/api-usage");
  }
  function getStartedButtonClick() {
    history.push("/api/verification/validate-phone")
  }
  function testApiButtonClick() {
    history.push("/api/verification/validate-phone/test-api");
  }

  let content;
  if (user.apiKeyIds['validate-phone-api'] !== undefined) {
    content = <ul className={classes.content}>

      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          component="button"
          variant="body2"
          className={loc.pathname === '/api/verification/validate-phone' ? classes.active : classes.isactive}
          onClick={getStartedButtonClick}
        >
          Get Started
        </Link>

      </li>

      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          component="button"
          variant="body2"
          className={loc.pathname === '/api/verification/validate-phone/test-api' ? classes.active : classes.isactive}
          onClick={testApiButtonClick}
        >
          Test API
        </Link>

      </li>

      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          variant="body2"
          component={RouterLink}
          to={{ pathname: "https://docs.apitier.com/docs/validate-phone-api/introduction" }}
          target="_blank"
          className={loc.pathname === '/api/verification/validate-phone/documentation' ? classes.active : classes.isactive}
        >
          Documentation
        </Link>

      </li>

      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          component="button"
          variant="body2"
          onClick={subscriptionButtonClick}
          className={loc.pathname === '/api/verification/validate-phone/subscription' ? classes.active : classes.isactive}
        >
          Subscription
        </Link>

      </li>

      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          variant="body2"
          component={RouterLink}
          to={{ pathname: "https://docs.apitier.com/apis/validate-phone-api" }}
          target="_blank"
          className={loc.pathname === '/api/verification/validate-phone/specification' ? classes.active : classes.isactive}
        >
          API Specification
        </Link>

      </li>

      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          component="button"
          variant="body2"
          onClick={apiUsageButtonClick}
          className={loc.pathname === '/api/verification/validate-phone/api-usage' ? classes.active : classes.isactive}

        >
          API Usage
        </Link>

      </li>
      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          component="button"
          variant="body2"
          onClick={pricingButtonClick}
          className={loc.pathname === '/api/verification/validate-phone/pricing' ? classes.active : classes.isactive}
        >
          Upgrade
        </Link>

      </li>
      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          component="button"
          variant="body2"
          onClick={securityButtonClick}
          className={loc.pathname === '/api/verification/validate-phone/security' ? classes.active : classes.isactive}
        >
          Security
        </Link>

      </li>
    </ul>
  } else {
    content = <ul className={classes.content}>
      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          component="button"
          variant="body2"
          className={loc.pathname === '/api/verification/validate-phone' ? classes.active : classes.isactive}
          onClick={getStartedButtonClick}
        >
          Get Started
        </Link>

      </li>


      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          variant="body2"
          component={RouterLink}
          to={{ pathname: "https://docs.apitier.com/docs/validate-phone-api/introduction" }}
          target="_blank"
          className={loc.pathname === '/api/verification/validate-phone/documentation' ? classes.active : classes.isactive}
        >
          Documentation
        </Link>

      </li>

      <li className={classes.contentli}>
        <Link
          style={{ textDecoration: 'none' }}
          variant="body2"
          component={RouterLink}
          to={{ pathname: "https://docs.apitier.com/apis/validate-phone-api" }}
          target="_blank"
          className={loc.pathname === '/api/verification/validate-phone/specification' ? classes.active : classes.isactive}
        >
          API Specification
        </Link>

      </li>

    </ul>
  }

  return (
    <div className={classes.root}>
      <div>
        <Card>
          <CardContent>
            {content}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};
SideBarMenu.propTypes = {
  user: PropTypes.object.isRequired
};

export default SideBarMenu;