import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme) => ({
  root: {},
  action: {
    marginBottom: theme.spacing(1),
    '& + &': {
      marginLeft: theme.spacing(1)
    }
  }
}));

const Header = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            variant="body1"
            color="inherit"
            to="/app"
            component={RouterLink}
          >
            Verification API
          </Link>

          <Link
            variant="body1"
            color="inherit"
            to="/api/verification/validate-phone"
            component={RouterLink}
          >
           Validate Phone
          </Link>


        </Breadcrumbs>
        <br />
        {<Typography
          variant="h3"
          color="textPrimary"
        >
          Validate Phone
        </Typography> }

      </Grid>

    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
