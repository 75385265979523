import React from 'react';

import {
  makeStyles,
  Card,
  CardContent,
  hexToRgb,
  rgbToHex,
  Button,
  SvgIcon,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { dashboardApiConfig } from 'src/config';
import Typography from '@material-ui/core/Typography';
import {
  PlusCircle as PlusCircleIcon
} from 'react-feather'
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: theme.spacing(3)
  },
  tab: {
    minWidth: 10,
    fontSize: 12
  },
  cardHeader: {
    color: hexToRgb('#787878')
  },
  title: {
    fontSize: 15,
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'serif'],
    fontWeight: 600,
    color: hexToRgb('#787878'),
  },
  pos: {
    fontSize: 15,
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'serif'],
    fontWeight: 600,
    color: hexToRgb('#787878'),
    paddingTop: 15,
  },
  GetStartedClass: {
    fontWeight: 'bold',
    color: rgbToHex('rgb(63,81,181)'),
  },
  DummyAPIkey: {
    textDecoration: 'line-through',
  }, 
  codeTag: {
    padding: 30,
    backgroundColor: hexToRgb('#F7F8FC')
  }
}));

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          <div>{children}</div>
        </div>
      )}
    </div>
  );
}

const GetStarted = ({ user }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const timer = React.useRef();
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    data: PropTypes.any.isRequired
  };

  const generateAPIKey = async () => {
    if (!loading) {
      setLoading(true);
      timer.current = setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
    try {
      const headers = {
        "x-api-key": dashboardApiConfig.dashboard_api_key,
        "Authorization": "Bearer " + localStorage.getItem('idToken')
      }
      const requestOptions = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          'apiKeyName': 'validate-phone-api',
          'usagePlanId': dashboardApiConfig.dashboard_validate_phone_api_usage_plan,
          'idToken': localStorage.getItem('idToken'),
          'userId': user.id,
          'requestLimit': 100
        })
      }
      fetch(dashboardApiConfig.dashboard_api_url + '/user/createapikey', requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data) {
            window.location.reload(false);
          }
        })
    } catch (err) {
      console.error(err);
    }
  };

  let content;
 
  if (user.apiKeyIds['validate-phone-api'] !== undefined) {

    content =
      <div>
        <Typography className={classes.pos} variant="body2" component="h4">
          This is your unique API Key to access Validate Phone API
        </Typography>
        <br />
        <Card className={classes.GetStartedClass}>
          <List>
            <ListItem key="1">
              <ListItemText
                primary={user.apiKeyIds['validate-phone-api'].apiKeyValue}
                primaryTypographyProps={{ variant: 'h4' }} />
            </ListItem>
          </List>
        </Card >
      </div>
  } else {
    content =
      <div>
        <Typography className={classes.pos} variant="body2" component="h4">
          Generate your unique API Key to access Validate Phone API
        </Typography>
        <br />
        <Card className={classes.GetStartedClass}>
          <List>
            <ListItem key="1">
              <ListItemText
                className={classes.DummyAPIkey}
                primary="xxxxxxxx-xxxxxxxx-xxxxxxxx-xxxxxxxx-xxxx"
                primaryTypographyProps={{ variant: 'h4' }} />
              <Button variant="contained"
                color="secondary"
                onClick={generateAPIKey}
                startIcon={
                  <SvgIcon fontSize="small">
                    <PlusCircleIcon />
                  </SvgIcon>
                }
                disabled={loading}
                > 
                Generate API KEY
                {loading && (
          <CircularProgress size={24}  />
        )}
                </Button>
            </ListItem>
          </List>
        </Card >
      </div>
  }


  return (
      <Card>
        <CardContent>
          <Typography  color="textPrimary" variant="h3">
            Get Started
          </Typography>
          <br/>
          <Typography  color="textSecondary" variant="h4">
           API Key
          </Typography>
          {content}
        </CardContent>
      </Card>
  );
};
GetStarted.propTypes = {
  user: PropTypes.object.isRequired
};

export default GetStarted;



