import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Hidden,
  Typography,
  Link,
  Divider,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Logo from 'src/components/Logo';
import APICatalogue from './APICatalogue';

const drawerWidth = 290;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  drawer: {
    width: drawerWidth,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3) 
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    justifyContent: 'space-between',
  },
  closeMenuButton: {
    marginLeft: 'auto',
  },
  menuIcon: {
    marginLeft: 'auto',
  },
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [productMenuOpen, setProductMenuOpen] = useState(false); 

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const toggleProductMenu = () => {
    setProductMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = () => {
    setDrawerOpen(false); 
  };

  const menuItems = [
    { text: 'AI QRCode', link: 'https://ai.qrcodes.apitier.com/login', external: true },
    { text: 'Free Tools', link: '/free-tools' },
    { text: 'Products', link: '#', onClick: toggleProductMenu }, 
    { text: 'Pricing', link: '/pricing-plans' },
    { text: 'Blog', link: 'https://blog.apitier.com/', external: true },
    { text: 'Sign In', link: '/signin' },
    { text: 'Sign Up', link: '/signup' }
  ];

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <Hidden mdDown>
          <Typography
            variant="caption"
            color="textSecondary"
          >
          </Typography>
        </Hidden>

        <Box flexGrow={1} />

        <Hidden smDown>
          <Link
            className={classes.link}
            color="textSecondary"
            component={RouterLink}
            to={{ pathname: "https://ai.qrcodes.apitier.com/login" }}
            underline="none"
            target="_blank"
            variant="body2"
          >
            AI QRCode
          </Link>
      
          <Link
            className={classes.link}
            color="textSecondary"
            component={RouterLink}
            to="/free-tools"
            underline="none"
            variant="body2"
          >
            Free Tools
          </Link>

          <Box ml={2}>
            <APICatalogue />
          </Box>

          <Link
            className={classes.link}
            color="textSecondary"
            component={RouterLink}
            to="/pricing-plans"
            underline="none"
            variant="body2"
          >
            Pricing
          </Link>

          <Link
            className={classes.link}
            color="textSecondary"
            component={RouterLink}
            to={{ pathname: "https://blog.apitier.com/" }}
            underline="none"
            target="_blank"
            variant="body2"
          >
            Blog
          </Link>

          <Link
            className={classes.link}
            color="textSecondary"
            component={RouterLink}
            to="/signin"
            underline="none"
            variant="body2"
          >
            Sign In
          </Link>
          <Divider className={classes.divider} />
          <Button
            color="secondary"
            component="a"
            href="/signup"
            variant="contained"
            size="small"
          >
            Sign Up
          </Button>
        </Hidden>

        <Hidden mdUp>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuIcon}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>

      <Hidden mdUp>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          classes={{ paper: classes.drawer }}
        >
          <div className={classes.drawerHeader}>
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
            <IconButton
              onClick={toggleDrawer(false)}
              className={classes.closeMenuButton}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  component={item.external ? 'a' : RouterLink}
                  href={item.external ? item.link : undefined}
                  to={item.external ? undefined : item.link}
                  target={item.external ? "_blank" : undefined}
                  onClick={item.onClick || handleMenuItemClick} 
                >
                  <ListItemText primary={item.text} />
                </ListItem>
                {index === 2 && productMenuOpen && ( 
                  <Box ml={2}>
                    <APICatalogue mobile />
                  </Box>
                )}
                {index < menuItems.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Drawer>
      </Hidden>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
