import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 78,
    paddingBottom: 78
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const SimpleSteps = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="h4"
          color="secondary"
          align="center"
        >
          HOW TO START?
        </Typography>
        <Box my={3}>
          <spacing />
        </Box>
        <Typography
          variant="h2"
          align="center"
          color="textPrimary"
        >
          Get Started In 3 Easy Steps
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  01
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Get Free API Key
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                     Register Free (without providing Card Details) with an email and password.
                  </Typography>
                    <Button
                      variant="outlined"
                      component="a"
                      href="/signup"
                      target="_blank"
                    >
                      Free SignUp
                    </Button>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  02
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Test & Integrate API
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    Test & Integrate Simple, self-explanatory APIs. Make use of the Free Code examples.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  03
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Go Live
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
              When you are ready to go live, purchase Pay As You Go API plans with a year of validity                  </Typography>
                    {/* <Button
                      variant="outlined"
                      component="a"
                      href="/pricing"
                      target="_blank"
                    >
                      Purchase Plan
                    </Button> */}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

SimpleSteps.propTypes = {
  className: PropTypes.string
};

export default SimpleSteps;
