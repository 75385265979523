import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import FreeTool from './FreeTool'
const useStyles = makeStyles(() => ({
  root: {}
}));

const FreeToolsView = () => {
  const classes = useStyles();

  return (

    <Page
      className={classes.root}
      title="Create Free Barcodes and QR Codes Instantly - Generate Now | APITier"
      description="Generate barcodes and QR codes quickly, easily, and for free. Create custom tags, labels, stickers, and more with our user-friendly online generator. Get your codes in minutes - start creating now!"
      canonicalURL="https://www.apitier.com/free-tools"
    >

      <FreeTool />

    </Page>
  );
};

export default FreeToolsView;
