import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  hexToRgb,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import track from 'src/utils/analytics';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 74,
    paddingBottom: 74,
    flexGrow: 1,
    paddingBottom: theme.spacing(3),
    border: "1px solid lightgray",
  },
  lookupLabel: {
    backgroundColor: hexToRgb("#4CAF50"),
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  product: {
    position: "relative",
    padding: theme.spacing(5, 3),
    cursor: "pointer",
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  productImage: {
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    top: -24,
    left: theme.spacing(3),
    height: 48,
    width: 48,
    fontSize: 24,
  },
  recommendedProduct: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  chooseButton: {
    backgroundColor: "#bfbebe",
  },
  dropbtn: {
    backgroundColor: hexToRgb("#4CAF50"),
    color: "white",
    padding: 16,
    fontSize: 16,
    border: "none",
    cursor: "pointer",
  },
  content: {
    border: "2px solid #dad6d6",
    boxShadow: "5px 10px 10px #dad6d6 ",
  },
  current: {
    margin: 60,
    border: "2px solid #dad6d6",
    boxShadow: "5px 10px 10px #dad6d6 ",
  },
  cardHeading: {
    fontSize: 20,
  },

  hr: {
    color: theme.palette.common.white,
  },
  priceBox: {
    paddingRight: 60,
  },
  cardHeader: {
    color: hexToRgb("#787878"),
    padding: 10,
  }, 
}));

const PricingView = () => {
  const classes = useStyles();

  const history = useHistory();
  
  useEffect(() => {
    track.event('apitier_pricing');
  }, []);

  function startupButtonClick() {
    track.event('apitier_startup_pricing');
    history.push("/pricing-plans-for-individual-developers-startups");
  }
  function smeButtonClick() {
    track.event('apitier_sme_pricing');
    history.push("/pricing-plans-for-small-and-medium-business");
  }
  function enterpriseButtonClick() {
    track.event('apitier_enterprise_pricing');
    history.push("/pricing-plans-for-enterprise-api-solutions");
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Typography component="p" variant="h4" color="secondary" align="center">
          Discover transparent pricing and start integrating today!!
        </Typography>

        <Box my={3}>
          <spacing />
        </Box>

        <Typography variant="h2" align="center" color="textPrimary">
          Business Pricing Plans
        </Typography>

        <Box mt={8}>
          <Grid container spacing={3}>

            <Grid item md={4} xs={12} className={classes.current}>
              <Box display="flex">
                <Paper className={clsx(classes.product)} elevation={1}>
                  <img
                    alt="Product"
                    className={classes.productImage}
                    src="/static/images/products/product_standard.svg"
                  />

                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="inherit"
                    className={classes.cardHeading}
                  >
                    StartUp
                  </Typography>
                  <Box my={2} className={classes.hr}>
                    <Divider />
                  </Box>
                  <Typography variant="body2" color="inherit">
                    <b className={classes.cardHeading}>
                      Plans Suitable For The Individual Developers & Early Stage
                      SartUps
                    </b>
                  </Typography>
                  <Box my={2} className={classes.hr}>
                    <Divider />
                  </Box>

                  <Typography variant="body2" color="inherit">
                    <br />
                    <b>Multi-tenant Cloud Environment</b>
                    <br />
                    <b>Basic Security Capabilities</b>
                    <br />
                    <b>Self Service Developer Portal</b>
                    <br />
                    <b>API Documentation</b>
                    <br />
                  </Typography>

                  <Box my={2} className={classes.hr}>
                    <Divider />
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={startupButtonClick}
                  >
                    Get Started Now
                  </Button>
                </Paper>
              </Box>
            </Grid>
            <Grid item md={4} xs={12} className={classes.current}>
              <Box display="flex">
                <Paper className={clsx(classes.product)} elevation={1}>
                  <img
                    alt="Product"
                    className={classes.productImage}
                    src="/static/images/products/product_premium--outlined.svg"
                  />

                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="inherit"
                    className={classes.cardHeading}
                  >
                    SME
                  </Typography>
                  <Box my={2} className={classes.hr}>
                    <Divider />
                  </Box>
                  <Typography variant="body2" color="inherit">
                    <b className={classes.cardHeading}>
                      Plans Suitable For The Small & Medium Size Business
                    </b>
                  </Typography>
                  <Box my={2} className={classes.hr}>
                    <Divider />
                  </Box>

                  <Typography variant="body2" color="inherit">
                    <br />
                    <b>Multi-Tenant & Single-Tenant Cloud Environment</b>
                    <br />
                    <b>Hybrid Cloud Deployment</b>
                    <br />
                    <b>Advanced Security Capabilities</b>
                    <br />
                    <b>Self Service Developer Portal</b>
                    <br />
                    <b>API Documentation</b>
                    <br />
                    <b>SLA Agreements</b>
                    <br />
                    <b>Client Integration Support</b>
                    <br />
                  </Typography>

                  <Box my={2} className={classes.hr}>
                    <Divider />
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={smeButtonClick}
                  >
                    Get Started Now
                  </Button>
                </Paper>
              </Box>
            </Grid>
            <Grid item md={4} xs={12} className={classes.current}>
              <Box display="flex">
                <Paper className={clsx(classes.product)} elevation={1}>
                  <img
                    alt="Product"
                    className={classes.productImage}
                    src="/static/images/products/product_premium--outlined.svg"
                  />

                  <Typography
                    component="h3"
                    gutterBottom
                    variant="overline"
                    color="inherit"
                    className={classes.cardHeading}
                  >
                    Enterprise
                  </Typography>
                  <Box my={2} className={classes.hr}>
                    <Divider />
                  </Box>
                  <Typography variant="body2" color="inherit">
                    <b className={classes.cardHeading}>
                      Plans Suitable For The Large Enterprises
                    </b>
                  </Typography>
                  <Box my={2} className={classes.hr}>
                    <Divider />
                  </Box>

                  <Typography variant="body2" color="inherit">
                    <br />
                    <b>Multi-Tenant & Single-Tenant Cloud Environment</b>
                    <br />
                    <b>Hybrid Cloud Deployment</b>
                    <br />
                    <b>On-Premises Deployment</b>
                    <br />
                    <b>Advanced Security Capabilities</b>
                    <br />
                    <b>Self Service Developer Portal</b>
                    <br />
                    <b>API Documentation</b>
                    <br />
                    <b>SLA Agreements</b>
                    <br />
                    <b>Client Integration Support</b>
                    <br />
                    <b>Enhanced Support</b>
                    <br />
                    <b>Support Bespoke Development & Custom UseCases</b>
                    <br />
                  </Typography>

                  <Box my={2} className={classes.hr}>
                    <Divider />
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    color="primary"
                    onClick={enterpriseButtonClick}
                  >
                    Get Started Now
                  </Button>
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

PricingView.propTypes = {
  data: PropTypes.any.isRequired,
};

export default PricingView;
