import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
  colors,
  Card,
  CardContent,
  CardActionArea,
  Avatar,
  SvgIcon,
} from '@material-ui/core';
import {
  Grid as GridIcon,
  BarChart2,
  Phone,
  ShoppingBag,
  FileText
} from 'react-feather';

import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 70,
    width: 70
  },
  avatar: {
    backgroundColor: colors.red[600]
  },
  stepper: {
    backgroundColor: 'transparent'
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const FreeTool = ({ className, ...rest }) => {
  const classes = useStyles();


  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div id="api-catalogue">

        <Container maxWidth="lg">

          <Typography
            variant="h1"
            align="center"
            color="textPrimary"
          >
            Free Tools for QR Codes, Barcode, UK Postcode Lookup & More...
          </Typography>

          <Box my={3}>
            <Divider />
          </Box>

          <Typography
            variant="h2"
            align="center"
            style={{ color: "#5850EC" }}
          >
            Get Free QRCodes For Your Business!!
          </Typography>
          <Box mt={8}>
            <Grid
              container
              spacing={3}
            >
      
              <Grid
                item
                xs={12}
                md={6}
              >
                <Card>
                  <CardActionArea
                    component={RouterLink}
                    to={{ pathname: "https://www.apitier.com/tools/free-qrcode-generator" }}
                    target='_blank' >
                    
                    <CardContent>

                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                      >
                        <Avatar
                          className={classes.icon}
                        >
                          <SvgIcon fontSize="large">
                            <GridIcon />
                          </SvgIcon>
                        </Avatar>

                        <Typography
                          variant="h2"
                          gutterBottom
                          color="textPrimary"
                        > AI Generated QR Codes
                        </Typography>
                        
                        <Divider />

                        <Typography
                          variant="body1"
                          gutterBottom
                          color="textPrimary"
                        >
                          Unlock the creativity of AI-generated artistic QR codes. Transform your brand, business, or artwork into captivating visual codes that engage and delight your audience. Discover the power of AI in creating unique and eye-catching QR codes that stand out from the crowd. Elevate your marketing strategy with our cutting-edge technology today!!
                        </Typography>
                      
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
              >
                <Card>
                  <CardActionArea
                    component={RouterLink}
                    to={{ pathname: "https://www.apitier.com/free-tools/artistic-qrcode-generator" }}
                    target='_blank' >
                    <CardContent>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                      >
                        <Avatar
                          className={classes.icon}
                        >
                          <SvgIcon fontSize="large">
                            <BarChart2 />
                          </SvgIcon>
                        </Avatar>
                        <Typography
                          variant="h2"
                          gutterBottom
                          color="textPrimary"
                        > Free Artistic QR Codes
                        </Typography>

                        <Divider />

                        <Typography
                          variant="body1"
                          gutterBottom
                          color="textPrimary"
                        >
                          Unlock the potential of your QR codes with our free artistic QR code generator. Create stunning and eye-catching QR codes that seamlessly blend with your brand's aesthetic. Drive engagement and enhance user experience with visually appealing QR codes that capture attention instantly. Start generating unique and captivating artistic QR codes for free today!! 
                          </Typography>

                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

            </Grid>
          </Box>

          <Box my={3}>
            <Divider />
          </Box>

          <Box mt={8}>
            <Grid
              container
              spacing={3}
            >
      
              <Grid
                item
                xs={12}
                md={6}
              >
                <Card>
                  <CardActionArea
                    component={RouterLink}
                    to={{ pathname: "https://www.ai-qrcodes-generator.com" }}
                    target='_blank' >
                    
                    <CardContent>

                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                      >
                        <Avatar
                          className={classes.icon}
                        >
                          <SvgIcon fontSize="large">
                            <GridIcon />
                          </SvgIcon>
                        </Avatar>

                        <Typography
                          variant="h2"
                          gutterBottom
                          color="textPrimary"
                        > Free QR Code Generator
                        </Typography>
                        
                        <Divider />

                        <Typography
                          variant="body1"
                          gutterBottom
                          color="textPrimary"
                        >
                          Generate high-quality, secure QR Codes quickly and easily with our QR Code Generator. Our easy-to-use platform lets you create custom codes with your own branding, data, and design. Get started today!!
                        </Typography>
                      
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
              >
                <Card>
                  <CardActionArea
                    component={RouterLink}
                    to={{ pathname: "https://www.ai-qrcodes-generator.com/free-barcode-generator" }}
                    target='_blank' >
                    <CardContent>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                      >
                        <Avatar
                          className={classes.icon}
                        >
                          <SvgIcon fontSize="large">
                            <BarChart2 />
                          </SvgIcon>
                        </Avatar>
                        <Typography
                          variant="h2"
                          gutterBottom
                          color="textPrimary"
                        > Free BarCode Generator
                        </Typography>

                        <Divider />

                        <Typography
                          variant="body1"
                          gutterBottom
                          color="textPrimary"
                        >
                          With our barcode generator, you can create and print barcodes of any size with ease. Our intuitive Tool allows you to customize your barcodes and add features like text, images, colors, and shapes. Create the perfect barcode for your Business!!                            </Typography>

                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
              >
                <Card>
                  <CardActionArea
                    component={RouterLink}
                    to={{ pathname: "https://www.apitier.com/free-tools/free-phone-validator" }}
                    target='_blank' >
                    <CardContent>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                      >
                        <Avatar
                          className={classes.icon}
                        >
                          <SvgIcon fontSize="large">
                            <Phone />
                          </SvgIcon>
                        </Avatar>
                        <Typography
                          variant="h2"
                          gutterBottom
                          color="textPrimary"
                        > Free Phone Validator
                        </Typography>

                        <Divider />

                        <Typography
                          variant="body1"
                          gutterBottom
                          color="textPrimary"
                        >
                         Easily verify phone numbers with APITier's powerful validation tool. Get accurate, real-time data to improve lead quality and prevent fraud. It's free to try - sign up now and start validating phone numbers in seconds.
                        </Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
              >
                <Card>
                  <CardActionArea
                    component={RouterLink}
                    to={{ pathname: "https://www.apitier.com/free-tools/convert-any-web-page-to-text-to-use-in-chatgpt" }}
                    target='_blank' >
                    
                    <CardContent>

                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                      >
                        <Avatar
                          className={classes.icon}
                        >
                          <SvgIcon fontSize="large">
                            <FileText />
                          </SvgIcon>
                        </Avatar>

                        <Typography
                          variant="h2"
                          gutterBottom
                          color="textPrimary"
                        >Free Web To Text 
                        </Typography>
                        
                        <Divider />

                        <Typography
                          variant="body1"
                          gutterBottom
                          color="textPrimary"
                        >
                          Easily convert web pages into readable text with APITier's Web to Text API. Ensure accuracy with real-time data extraction and streamline data processing. Try it for free - sign up now and start extracting text from web pages instantly.
                        </Typography>
                      
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
              >
                <Card>
                  <CardActionArea
                    component={RouterLink}
                    to={{ pathname: "https://www.apitier.com/free-tools/free-vat-number-validator" }}
                    target='_blank' >
                    <CardContent>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                      >
                        <Avatar
                          className={classes.icon}
                        >
                          <SvgIcon fontSize="large">
                            <ShoppingBag />
                          </SvgIcon>
                        </Avatar>
                        <Typography
                          variant="h2"
                          gutterBottom
                          color="textPrimary"
                        >VAT Number Validator
                        </Typography>

                        <Divider />

                        <Typography
                          variant="body1"
                          gutterBottom
                          color="textPrimary"
                        >
                         Quickly and accurately verify VAT numbers using APITier's advanced validation tool. Ensure accuracy with real-time data and reduce errors with reliable, up-to-date information. Try it for free - sign up now and start validating VAT numbers instantly.
                        </Typography>

                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>

            </Grid>
          </Box>

          <Box my={3}>
            <Divider />
          </Box>


        </Container>
      </div>
    </div >
  );
};

FreeTool.propTypes = {
  className: PropTypes.string
};

export default FreeTool;
