import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axiosInstance from 'src/utils/axiosInstance';
import { dashboardApiConfig } from 'src/config';

//set dummy default header
const headers = {
  "x-api-key": dashboardApiConfig.dashboard_api_key
}

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken, idToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('idToken', idToken);
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${idToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('idToken');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  sociallogin: () => Promise.resolve(),
  logout: () => { }, 
  socialregister: () => Promise.resolve(),
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);


  const sociallogin = async (email) => {
    const apiResponse = await axiosInstance.post(dashboardApiConfig.dashboard_api_url + '/auth/socialsignin',
      {
        'username': email
      },
      { headers: headers });

    const accessToken = apiResponse.data.AuthenticationResult.AccessToken;
    const idToken = apiResponse.data.AuthenticationResult.IdToken;
    const user = apiResponse.data.UserData;
 
    // console.log('LOGIN user ', apiResponse.data.UserData);
    setSession(accessToken, idToken);
    // console.log('JWTAuthContext userData ', user);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const login = async (email, password) => {
    const apiResponse = await axiosInstance.post(dashboardApiConfig.dashboard_api_url + '/auth/signin',
      {
        'username': email,
        'password': password
      },
      { headers: headers });

    const accessToken = apiResponse.data.AuthenticationResult.AccessToken;
    const idToken = apiResponse.data.AuthenticationResult.IdToken;
    const user = apiResponse.data.UserData;
 
    // console.log('LOGIN user ', apiResponse.data.UserData);
    setSession(accessToken, idToken);
    // console.log('JWTAuthContext userData ', user);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const socialregister = async (email, name) => {
    const response = await axiosInstance.post(dashboardApiConfig.dashboard_api_url + '/auth/socialsignup',
      {
        'username': email
      },
      { headers: headers });
    const accessToken = response.data.AuthenticationResult.AccessToken;
    const user = response.data.UserData;
    //                console.log('REGISTER user ',response.data.UserData);
    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const register = async (email, name, password) => {
    const response = await axiosInstance.post(dashboardApiConfig.dashboard_api_url + '/auth/signup',
      {
        'username': email,
        'password': password
      },
      { headers: headers });
    const accessToken = response.data.AuthenticationResult.AccessToken;
    const user = response.data.UserData;
    //                console.log('REGISTER user ',response.data.UserData);
    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        const idToken = window.localStorage.getItem('idToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken, idToken);
          const apiResponse = await axiosInstance.post(dashboardApiConfig.dashboard_api_url + '/auth/aboutme',
            {
              'idToken': idToken
            },
            { headers: headers });
          const user = apiResponse.data;
          //            console.log('INITIALISE user ',apiResponse.data);
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        sociallogin,
        logout,
        socialregister,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;