import mock from 'src/utils/mock';

const notifications = [
  {
    id: '5e8883f1b51cc1956a5a1ec0',
    title: 'Verification API',
    type: 'verification_api',
    link: '/api-products#verification-api'
  },
  {
    id: '5e8883f7ed1486d665d8be1f',
    title: 'UK PostCode API',
    type: 'uk_postcode_api',
    link: '/api-products#uk-postcode-api'
  },
  {
    id: '5e8883f7ed1486d665d8be1e',
    title: 'WEB Conversion API',
    type: 'web_conversion_api',
    link: '/api-products#web-conversion-api'
  },
  {
    id: '5e8883fca0e8612044248ecf',
    title: 'DATA Conversion API',
    type: 'data_conversion_api',
    link: '/api-products#data-conversion-api'
  },
  {
    id: '5e8883fca0e8612044248ece',
    title: 'INDIA PinCode API',
    type: 'in_pincode_api',
    link: '/api-products#india-pincode-api'
  },
  {
    id: '5e8883fca0e8612044248ecd',
    title: 'QRCODE API',
    type: 'barcode_api',
    link: '/api-products#barcode-api'
  },

];


mock.onGet('/api/notifications').reply(200, { notifications });

