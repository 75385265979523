import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import HomeView from 'src/views/home/HomeView';
import ProductListView from 'src/views/dashboard/ProductListView';
import APICatalogueView from 'src/views/dashboard/APICatalogueView';
import FreeToolsView from 'src/views/dashboard/FreeToolsView';

import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import ValidatePhoneView from 'src/views/apis/verification/phone/ValidatePhoneView';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [

  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/forgot-password',
    component: lazy(() => import('src/views/auth/ForgetPasswordView'))
  },
  {
    exact: true,
    path: '/confirm-password',
    component: lazy(() => import('src/views/auth/ConfirmPasswordView'))
  },
  {
    path: '/api',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/api',
        component: () => <Redirect to="/api/dashboard" />
      },
      {
        exact: true,
        path: '/api/dashboard',
        component: lazy(() => import('src/views/apis/DashboardView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-postcode',
        component: lazy(() => import('src/views/apis/findbypostcode/FindByPostCodeView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-postcode/test-api',
        component: lazy(() => import('src/views/apis/findbypostcode/FindByPostCodeTestView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-postcode/subscription',
        component: lazy(() => import('src/views/apis/findbypostcode/FindByPostCodeSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-postcode/api-usage',
        component: lazy(() => import('src/views/apis/findbypostcode/FindByPostCodeApiUsageView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-postcode/pricing',
        component: lazy(() => import('src/views/apis/findbypostcode/FindByPostCodePricing/PricingView'))
      },     
      {
        exact: true,
        path: '/api/postcode/find-by-postcode/payment/cancel',
        component: lazy(() => import('src/views/apis/findbypostcode/FindByPostCodePricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-postcode/payment/success',
        component: lazy(() => import('src/views/apis/findbypostcode/FindByPostCodePricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-postcode/security',
        component: lazy(() => import('src/views/apis/findbypostcode/FindByPostCodeSecurityView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-addresses',
        component: lazy(() => import('src/views/apis/findbyaddress/FindByAddressesView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-addresses/test-api',
        component: lazy(() => import('src/views/apis/findbyaddress/FindByAddressesTestView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-addresses/subscription',
        component: lazy(() => import('src/views/apis/findbyaddress/FindByAddressesSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-addresses/api-usage',
        component: lazy(() => import('src/views/apis/findbyaddress/FindByAddressesApiUsageView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-addresses/pricing',
        component: lazy(() => import('src/views/apis/findbyaddress/FindByAddressesPricing/PricingView'))
      },     
      {
        exact: true,
        path: '/api/postcode/find-by-addresses/payment/cancel',
        component: lazy(() => import('src/views/apis/findbyaddress/FindByAddressesPricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-addresses/payment/success',
        component: lazy(() => import('src/views/apis/findbyaddress/FindByAddressesPricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-addresses/security',
        component: lazy(() => import('src/views/apis/findbyaddress/FindByAddressesSecurityView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-udprn',
        component: lazy(() => import('src/views/apis/findbyudprn/FindByUDPRNView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-udprn/test-api',
        component: lazy(() => import('src/views/apis/findbyudprn/FindByUDPRNTestView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-udprn/subscription',
        component: lazy(() => import('src/views/apis/findbyudprn/FindByUDPRNSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-udprn/api-usage',
        component: lazy(() => import('src/views/apis/findbyudprn/FindByUDPRNApiUsageView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-udprn/pricing',
        component: lazy(() => import('src/views/apis/findbyudprn/FindByUDPRNPricing/PricingView'))
      },     
      {
        exact: true,
        path: '/api/postcode/find-by-udprn/payment/cancel',
        component: lazy(() => import('src/views/apis/findbyudprn/FindByUDPRNPricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-udprn/payment/success',
        component: lazy(() => import('src/views/apis/findbyudprn/FindByUDPRNPricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/postcode/find-by-udprn/security',
        component: lazy(() => import('src/views/apis/findbyudprn/FindByUDPRNSecurityView'))
      },
      {
        exact: true,
        path: '/api/postcode/address-autosuggest',
        component: lazy(() => import('src/views/apis/addressautosuggest/AddressAutosuggestView'))
      },
      {
        exact: true,
        path: '/api/postcode/address-autosuggest/test-api',
        component: lazy(() => import('src/views/apis/addressautosuggest/AddressAutosuggestTestView'))
      },
      {
        exact: true,
        path: '/api/postcode/address-autosuggest/subscription',
        component: lazy(() => import('src/views/apis/addressautosuggest/AddressAutosuggestSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/postcode/address-autosuggest/api-usage',
        component: lazy(() => import('src/views/apis/addressautosuggest/AddressAutosuggestApiUsageView'))
      },
      {
        exact: true,
        path: '/api/postcode/address-autosuggest/pricing',
        component: lazy(() => import('src/views/apis/addressautosuggest/AddressAutosuggestPricing/PricingView'))
      },     
      {
        exact: true,
        path: '/api/postcode/address-autosuggest/payment/cancel',
        component: lazy(() => import('src/views/apis/addressautosuggest/AddressAutosuggestPricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/postcode/address-autosuggest/payment/success',
        component: lazy(() => import('src/views/apis/addressautosuggest/AddressAutosuggestPricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/postcode/address-autosuggest/security',
        component: lazy(() => import('src/views/apis/addressautosuggest/AddressAutosuggestSecurityView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-email',
        component: lazy(() => import('src/views/apis/verification/email/ValidateEmailView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-email/test-api',
        component: lazy(() => import('src/views/apis/verification/email/ValidateEmailTestView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-email/api-usage',
        component: lazy(() => import('src/views/apis/verification/email/ValidateEmailApiUsageView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-email/subscription',
        component: lazy(() => import('src/views/apis/verification/email/ValidationEmailSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-email/security',
        component: lazy(() => import('src/views/apis/verification/email/ValidateEmailSecurityView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-email/pricing',
        // component: lazy(() => import('src/views/apis/verification/email/ValidateEmailPricing/PricingView'))
        component: lazy(() => import('src/views/dashboard/BusinessPricing/PricingPlanView'))
      },     
      {
        exact: true,
        path: '/api/verification/validate-email/payment/cancel',
        component: lazy(() => import('src/views/apis/verification/email/ValidateEmailPricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-email/payment/success',
        component: lazy(() => import('src/views/apis/verification/email/ValidateEmailPricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-phone',
        component: lazy(() => import('src/views/apis/verification/phone/ValidatePhoneView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-phone/test-api',
        component: lazy(() => import('src/views/apis/verification/phone/ValidatePhoneTestView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-phone/api-usage',
        component: lazy(() => import('src/views/apis/verification/phone/ValidatePhoneApiUsageView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-phone/subscription',
        component: lazy(() => import('src/views/apis/verification/phone/ValidationPhoneSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-phone/pricing',
        // component: lazy(() => import('src/views/apis/verification/phone/ValidatePhonePricing/PricingView'))
        component: lazy(() => import('src/views/dashboard/BusinessPricing/PricingPlanView'))
      }, 
      {
        exact: true,
        path: '/api/verification/validate-phone/payment/cancel',
        component: lazy(() => import('src/views/apis/verification/phone/ValidatePhonePricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-phone/payment/success',
        component: lazy(() => import('src/views/apis/verification/phone/ValidatePhonePricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-phone/security',
        component: lazy(() => import('src/views/apis/verification/phone/ValidatePhoneSecurityView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-vat',
        component: lazy(() => import('src/views/apis/verification/vat/ValidateVATView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-vat/test-api',
        component: lazy(() => import('src/views/apis/verification/vat/ValidateVATTestView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-vat/api-usage',
        component: lazy(() => import('src/views/apis/verification/vat/ValidateVATApiUsageView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-vat/subscription',
        component: lazy(() => import('src/views/apis/verification/vat/ValidationVATSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-vat/pricing',
        // component: lazy(() => import('src/views/apis/verification/vat/ValidateVATPricing/PricingView'))
        component: lazy(() => import('src/views/dashboard/BusinessPricing/PricingPlanView'))
      }, 
      {
        exact: true,
        path: '/api/verification/validate-vat/payment/cancel',
        component: lazy(() => import('src/views/apis/verification/vat/ValidateVATPricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-vat/payment/success',
        component: lazy(() => import('src/views/apis/verification/vat/ValidateVATPricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-vat/security',
        component: lazy(() => import('src/views/apis/verification/vat/ValidateVATSecurityView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-useragent',
        component: lazy(() => import('src/views/apis/verification/useragent/ValidateUserAgentView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-useragent/parse-useragent',
        component: lazy(() => import('src/views/apis/verification/useragent/ValidateUserAgentTestView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-useragent/generate-useragent',
        component: lazy(() => import('src/views/apis/verification/useragent/GenerateUserAgentTestView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-useragent/api-usage',
        component: lazy(() => import('src/views/apis/verification/useragent/ValidateUserAgentApiUsageView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-useragent/subscription',
        component: lazy(() => import('src/views/apis/verification/useragent/ValidationUserAgentSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-useragent/pricing',
        // component: lazy(() => import('src/views/apis/verification/useragent/ValidateUserAgentPricing/PricingView'))
        component: lazy(() => import('src/views/dashboard/BusinessPricing/PricingPlanView'))
      }, 
      {
        exact: true,
        path: '/api/verification/validate-useragent/payment/cancel',
        component: lazy(() => import('src/views/apis/verification/useragent/ValidateUserAgentPricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-useragent/payment/success',
        component: lazy(() => import('src/views/apis/verification/useragent/ValidateUserAgentPricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/verification/validate-useragent/security',
        component: lazy(() => import('src/views/apis/verification/useragent/ValidateUserAgentSecurityView'))
      },
      {
        exact: true,
        path: '/api/conversion/web-conversion',
        component: lazy(() => import('src/views/apis/conversion/web/WebConversionView'))
      },
      {
        exact: true,
        path: '/api/conversion/web-conversion/web-screenshot',
        component: lazy(() => import('src/views/apis/conversion/web/ScreenShotAPITestView'))
      },
      {
        exact: true,
        path: '/api/conversion/web-conversion/web-pdf',
        component: lazy(() => import('src/views/apis/conversion/web/WebToPDFAPITestView'))
      },
      {
        exact: true,
        path: '/api/conversion/web-conversion/web-scrape',
        component: lazy(() => import('src/views/apis/conversion/web/WebScrapeAPITestView'))
      },
      {
        exact: true,
        path: '/api/conversion/web-conversion/web-text',
        component: lazy(() => import('src/views/apis/conversion/web/WebToTextAPITestView'))
      },
      {
        exact: true,
        path: '/api/conversion/web-conversion/api-usage',
        component: lazy(() => import('src/views/apis/conversion/web/WebConversionApiUsageView'))
      },
      {
        exact: true,
        path: '/api/conversion/web-conversion/subscription',
        component: lazy(() => import('src/views/apis/conversion/web/WebConversionSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/conversion/web-conversion/pricing',
        // component: lazy(() => import('src/views/apis/conversion/web/WebConversionPricing/PricingView'))
        component: lazy(() => import('src/views/dashboard/BusinessPricing/PricingPlanView'))
      }, 
      {
        exact: true,
        path: '/api/conversion/web-conversion/payment/cancel',
        component: lazy(() => import('src/views/apis/conversion/web/WebConversionPricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/conversion/web-conversion/payment/success',
        component: lazy(() => import('src/views/apis/conversion/web/WebConversionPricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/conversion/web-conversion/security',
        component: lazy(() => import('src/views/apis/conversion/web/WebConversionSecurityView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion',
        component: lazy(() => import('src/views/apis/conversion/data/DataConversionView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion/json-xml',
        component: lazy(() => import('src/views/apis/conversion/data/JsonToXmlAPITestView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion/xml-json',
        component: lazy(() => import('src/views/apis/conversion/data/XmlToJsonAPITestView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion/json-csv',
        component: lazy(() => import('src/views/apis/conversion/data/JsonToCsvAPITestView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion/csv-json',
        component: lazy(() => import('src/views/apis/conversion/data/CsvToJsonAPITestView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion/csv-xml',
        component: lazy(() => import('src/views/apis/conversion/data/CsvToXmlAPITestView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion/api-usage',
        component: lazy(() => import('src/views/apis/conversion/data/DataConversionApiUsageView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion/subscription',
        component: lazy(() => import('src/views/apis/conversion/data/DataConversionSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion/pricing',
        // component: lazy(() => import('src/views/apis/conversion/data/DataConversionPricing/PricingView'))
        component: lazy(() => import('src/views/dashboard/BusinessPricing/PricingPlanView'))
      }, 
      {
        exact: true,
        path: '/api/conversion/data-conversion/payment/cancel',
        component: lazy(() => import('src/views/apis/conversion/data/DataConversionPricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion/payment/success',
        component: lazy(() => import('src/views/apis/conversion/data/DataConversionPricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/conversion/data-conversion/security',
        component: lazy(() => import('src/views/apis/conversion/data/DataConversionSecurityView'))
      },
      {
        exact: true,
        path: '/api/pincode/find-by-pincode',
        component: lazy(() => import('src/views/apis/pincode/findbypincode/FindByPincodeView'))
      },
      {
        exact: true,
        path: '/api/pincode/find-by-pincode/test-api',
        component: lazy(() => import('src/views/apis/pincode/findbypincode/FindByPincodeTestView'))
      },
      {
        exact: true,
        path: '/api/pincode/find-by-pincode/places/test-api',
        component: lazy(() => import('src/views/apis/pincode/findbypincode/FindByPincodePlacesTestView'))
      },
      {
        exact: true,
        path: '/api/pincode/find-by-pincode/api-usage',
        component: lazy(() => import('src/views/apis/pincode/findbypincode/FindByPincodeApiUsageView'))
      },
      {
        exact: true,
        path: '/api/pincode/find-by-pincode/subscription',
        component: lazy(() => import('src/views/apis/pincode/findbypincode/FindByPincodeSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/pincode/find-by-pincode/pricing',
        // component: lazy(() => import('src/views/apis/pincode/findbypincode/FindByPincodePricing/PricingView'))
        component: lazy(() => import('src/views/dashboard/BusinessPricing/PricingPlanView'))
      },     
      {
        exact: true,
        path: '/api/pincode/find-by-pincode/payment/cancel',
        component: lazy(() => import('src/views/apis/pincode/findbypincode/FindByPincodePricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/pincode/find-by-pincode/payment/success',
        component: lazy(() => import('src/views/apis/pincode/findbypincode/FindByPincodePricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/pincode/find-by-pincode/security',
        component: lazy(() => import('src/views/apis/pincode/findbypincode/FindByPincodeSecurityView'))
      },
      {
        exact: true,
        path: '/api/barcode-api',
        component: lazy(() => import('src/views/apis/barcode/findbybarcode/FindByBarcodeView'))
      },
      {
        exact: true,
        path: '/api/barcode-api/test-api/generatebarcode',
        component: lazy(() => import('src/views/apis/barcode/findbybarcode/GenerateBarcodeTestView'))
      },
      {
        exact: true,
        path: '/api/barcode-api/test-api/generateqrcode',
        component: lazy(() => import('src/views/apis/barcode/findbybarcode/GenerateQRCodeTestView'))
      },
      // {
      //   exact: true,
      //   path: '/api/barcode-api/test-api/scanbarcode',
      //   component: lazy(() => import('src/views/apis/barcode/findbybarcode/ScanBarcodeTestView'))
      // },
      {
        exact: true,
        path: '/api/barcode-api/api-usage',
        component: lazy(() => import('src/views/apis/barcode/findbybarcode/FindByBarcodeApiUsageView'))
      },
      {
        exact: true,
        path: '/api/barcode-api/subscription',
        component: lazy(() => import('src/views/apis/barcode/findbybarcode/FindByBarcodeSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/barcode-api/pricing',
        // component: lazy(() => import('src/views/apis/barcode/findbybarcode/FindByBarcodePricing/PricingView'))
        component: lazy(() => import('src/views/dashboard/BusinessPricing/PricingPlanView'))
      },     
      {
        exact: true,
        path: '/api/barcode-api/payment/cancel',
        component: lazy(() => import('src/views/apis/barcode/findbybarcode/FindByBarcodePricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/barcode-api/payment/success',
        component: lazy(() => import('src/views/apis/barcode/findbybarcode/FindByBarcodePricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/barcode-api/security',
        component: lazy(() => import('src/views/apis/barcode/findbybarcode/FindByBarcodeSecurityView'))
      },
      {
        exact: true,
        path: '/api/ai-qrcode-api',
        component: lazy(() => import('src/views/apis/aiqrcode/AIQRCodeView'))
      },
      {
        exact: true,
        path: '/api/ai-qrcode-api/test-api/ai-qrcode',
        component: lazy(() => import('src/views/apis/aiqrcode/GenerateAIQRCodeTestView'))
      },
      {
        exact: true,
        path: '/api/ai-qrcode-api/test-api/ai-qrcode-view',
        component: lazy(() => import('src/views/apis/aiqrcode/AIQRCodeTestView'))
      },
      {
        exact: true,
        path: '/api/ai-qrcode-api/api-usage',
        component: lazy(() => import('src/views/apis/aiqrcode/AIQRCodeApiUsageView'))
      },
      {
        exact: true,
        path: '/api/ai-qrcode-api/subscription',
        component: lazy(() => import('src/views/apis/aiqrcode/AIQRCodeSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/ai-qrcode-api/pricing',
        component: lazy(() => import('src/views/apis/aiqrcode/AIQRCodePricing/PricingView'))
      },     
      {
        exact: true,
        path: '/api/ai-qrcode-api/payment/cancel',
        component: lazy(() => import('src/views/apis/aiqrcode/AIQRCodePricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/ai-qrcode-api/payment/success',
        component: lazy(() => import('src/views/apis/aiqrcode/AIQRCodePricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/ai-qrcode-api/security',
        component: lazy(() => import('src/views/apis/aiqrcode/AIQRCodeSecurityView'))
      },
      {
        exact: true,
        path: '/api/postcode/api-usage',
        component: lazy(() => import('src/views/apis/PostCodeApiUsageView'))
      },
      {
        exact: true,
        path: '/api/postcode/subscription',
        component: lazy(() => import('src/views/apis/PostCodeApiSubscriptionView'))
      },
      {
        exact: true,
        path: '/api/postcode/pricing',
        component: lazy(() => import('src/views/apis/PostCodePricing/PricingView'))
      },     
      {
        exact: true,
        path: '/api/postcode/payment/cancel',
        component: lazy(() => import('src/views/apis/PostCodePricing/CancelView'))
      },
      {
        exact: true,
        path: '/api/postcode/payment/success',
        component: lazy(() => import('src/views/apis/PostCodePricing/SuccessView'))
      },
      {
        exact: true,
        path: '/api/payment/billing',
        component: lazy(() => import('src/views/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/api/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/api/pricing-plans',
        component: lazy(() => import('src/views/dashboard/BusinessPricing/APIPricingPlanView'))
      },
      {
        exact: true,
        path: '/api/pricing-plans-for-individual-developers-startups',
        component: lazy(() => import('src/views/dashboard/BusinessPricing/StartUpPricing'))
      },
      {
        exact: true,
        path: '/api/pricing-plans-for-small-and-medium-business',
        component: lazy(() => import('src/views/dashboard/BusinessPricing/SMEPricing'))
      },
      {
        exact: true,
        path: '/api/pricing-plans-for-enterprise-api-solutions',
        component: lazy(() => import('src/views/dashboard/BusinessPricing/EnterprisePricing'))
      },
      {
        component: () => <Redirect to="/404" />
      },
    ]
  },
  {
    path: '/resource',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/resource',
        component: () => <Redirect to="/resource/http-status-codes" />
      },
      {
        exact: true,
        path: '/resource/http-status-codes',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/100',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_100'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/101',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_101'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/102',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_102'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/103',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_103'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/200',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_200'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/201',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_201'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/202',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_202'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/203',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_203'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/204',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_204'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/205',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_205'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/206',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_206'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/207',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_207'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/208',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_208'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/226',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_226'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/300',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_300'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/301',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_301'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/302',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_302'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/303',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_303'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/304',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_304'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/305',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_305'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/307',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_307'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/308',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_308'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/400',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_400'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/401',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_401'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/402',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_402'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/403',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_403'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/404',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_404'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/405',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_405'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/406',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_406'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/407',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_407'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/408',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_408'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/409',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_409'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/410',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_410'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/411',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_411'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/412',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_412'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/413',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_413'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/414',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_414'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/415',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_415'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/416',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_416'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/417',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_417'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/418',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_418'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/421',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_421'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/422',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_422'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/423',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_423'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/424',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_424'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/425',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_425'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/426',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_426'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/428',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_428'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/429',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_429'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/431',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_431'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/444',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_444'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/451',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_451'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/499',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_499'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/500',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_500'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/501',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_501'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/502',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_502'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/503',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_503'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/504',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_504'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/505',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_505'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/506',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_506'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/507',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_507'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/508',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_508'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/510',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_510'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/511',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_511'))
      },
      {
        exact: true,
        path: '/resource/http-status-codes/599',
        component: lazy(() => import('src/views/docs/HTTPStatusCodes/HTTPStatusCodes_599'))
      },     
    ]
  },
  {
    path: '*',
    guard: GuestGuard,
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        exact: true,
        path: '/api-products',
        component: ProductListView
      },
      {
        exact: true,
        path: '/api-catalogue',
        component: APICatalogueView
      },
      {
        exact: true,
        path: '/free-tools',
        component: FreeToolsView
      },
      {
        exact: true,
        path: '/free-tools/free-phone-validator',
        component: lazy(() => import('src/views/dashboard/FreePhoneValidatorView'))
      },
      {
        exact: true,
        path: '/free-tools/free-vat-number-validator',
        component: lazy(() => import('src/views/dashboard/FreeValidateVATView'))
      },
      {
        exact: true,
        path: '/free-tools/convert-any-web-page-to-text-to-use-in-chatgpt',
        component: lazy(() => import('src/views/dashboard/FreeWebToTextView'))
      },
      {
        exact: true,
        path: '/api-catalogue/postcode-lookup-api',
        component: lazy(() => import('src/views/dashboard/PostCodeLookupView'))
      },
      {
        exact: true,
        path: '/api-catalogue/address-lookup-api',
        component: lazy(() => import('src/views/dashboard/AddressLookupView'))
      },
      {
        exact: true,
        path: '/api-catalogue/validate-email-api',
        component: lazy(() => import('src/views/dashboard/ValidateEmailView'))
      },
      {
        exact: true,
        path: '/api-catalogue/validate-phone-api',
        component: lazy(() => import('src/views/dashboard/ValidatePhoneView'))
      },
      {
        exact: true,
        path: '/api-catalogue/validate-useragent-api',
        component: lazy(() => import('src/views/dashboard/ValidateUAView'))
      },
      {
        exact: true,
        path: '/api-catalogue/validate-vat-api',
        component: lazy(() => import('src/views/dashboard/ValidateVATView'))
      },
      {
        exact: true,
        path: '/api-catalogue/address-autosuggest-api',
        component: lazy(() => import('src/views/dashboard/AddressAutoSuggestView'))
      },
      {
        exact: true,
        path: '/api-catalogue/screenshot-api',
        component: lazy(() => import('src/views/dashboard/ScreenShotAPIView'))
      },
      {
        exact: true,
        path: '/api-catalogue/web-to-pdf-api',
        component: lazy(() => import('src/views/dashboard/WebToPDFAPIView'))
      },
      {
        exact: true,
        path: '/api-catalogue/web-to-text-api',
        component: lazy(() => import('src/views/dashboard/WebToTextAPIView'))
      },
      {
        exact: true,
        path: '/api-catalogue/web-scrape-api',
        component: lazy(() => import('src/views/dashboard/WebScrapeAPIView'))
      },
      {
        exact: true,
        path: '/api-catalogue/data-conversion-api',
        component: lazy(() => import('src/views/dashboard/DataConversionView'))
      },
      {
        exact: true,
        path: '/api-catalogue/barcode-api',
        component: lazy(() => import('src/views/dashboard/BarCodeAPIView'))
      },
      // {
      //   exact: true,
      //   path: '/free-barcode',
      //   component: lazy(() => import('src/views/dashboard/FreeBarCodeView'))
      // },      
      {
        exact: true,
        path: '/api-catalogue/qrcode-api',
        component: lazy(() => import('src/views/dashboard/QRCodeAPIView'))
      },
      {
        exact: true,
        path: '/free-tools/artistic-qrcode-generator',
        component: lazy(() => import('src/views/dashboard/AIQRCodeAPIView'))
      },
      {
        exact: true,
        path: '/tools/free-qrcode-generator',
        component: lazy(() => import('src/views/dashboard/QRCodeGeneratorView'))
      },

      {
        exact: true,
        path: '/tools/free-qrcode-generator/:tabId',
        component: lazy(() => import('src/views/dashboard/QRCodeGeneratorView'))
      },
     
      {
        exact: true,
        path: '/tools/free-barcode-generator',
        component: lazy(() => import('src/views/dashboard/BarCodeGeneratorView'))
      },
     
      {
        exact: true,
        path: '/tools/free-barcode-generator/:barcodeId',
        component: lazy(() => import('src/views/dashboard/BarCodeGeneratorView'))
      },
      {
        exact: true,
        path: '/tools/free-barcode-generator/:barcodeId/:subBarcodeId',
        component: lazy(() => import('src/views/dashboard/BarCodeGeneratorView'))
      },
         
      {
        exact: true,
        path: '/api-catalogue/india-pincode-api',
        component: lazy(() => import('src/views/dashboard/PinCodeAPIView'))
      },
      {
        exact: true,
        path: '/pricing-plans',
        component: lazy(() => import('src/views/dashboard/BusinessPricing/PricingPlanView'))
      },
      {
        exact: true,
        path: '/pricing-plans-for-individual-developers-startups',
        component: lazy(() => import('src/views/dashboard/BusinessPricing/StartUpPricing'))
      },
      {
        exact: true,
        path: '/pricing-plans-for-small-and-medium-business',
        component: lazy(() => import('src/views/dashboard/BusinessPricing/SMEPricing'))
      },
      {
        exact: true,
        path: '/pricing-plans-for-enterprise-api-solutions',
        component: lazy(() => import('src/views/dashboard/BusinessPricing/EnterprisePricing'))
      },
      {
        exact: true,
        path: '/legal/privacy-policy',
        component: lazy(() => import('src/views/legal/Privacy'))
      },
      {
        exact: true,
        path: '/legal/terms-of-service',
        component: lazy(() => import('src/views/legal/TermsOfService'))
      },
      {
        exact: true,
        path: '/legal/royal-mail-end-user-licence',
        component: lazy(() => import('src/views/legal/Licence'))
      },
      {
        exact: true,
        path: '/signin',
        component: lazy(() => import('src/views/auth/LoginView'))
      },
      {
        exact: true,
        path: '/signup',
        component: lazy(() => import('src/views/auth/RegisterView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
