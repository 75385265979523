import React, {
  forwardRef,
  useEffect,
  useCallback
} from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import track from 'src/utils/analytics';

const Page = forwardRef(({
  children,
  title = '',
  description ='',
  keywords='',
  canonicalURL ='',
  structuredJSON = '',
  ...rest
}, ref) => {
  const location = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: location.pathname
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <div
      ref={ref}
      {...rest}
    >

      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={canonicalURL} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={description} />  
        <meta property="og:url" content={canonicalURL} />      
        <script className='structured-data-list' type="application/ld+json">{structuredJSON}</script>

      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  canonicalURL: PropTypes.string,
  structuredJSON: PropTypes.string

};

export default Page;
