import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  makeStyles,
  Box,
  ButtonBase,
  Hidden
} from '@material-ui/core';
import { AiOutlineDown } from "react-icons/ai";
import {
  AtSign,
  Home,
  Monitor,
  Database,
  Search,
  BarChart2
} from 'react-feather';
import { useDispatch, useSelector } from 'src/store';
import { getNotifications } from 'src/slices/notification';

const iconsMap = {
  verification_api: AtSign,
  uk_postcode_api: Home,
  web_conversion_api: Monitor,
  data_conversion_api: Database,
  barcode_api: BarChart2,
  in_pincode_api: Search
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  icon: { 
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const APICatalogue = ({ mobile }) => {
  const classes = useStyles();
  const { notifications } = useSelector((state) => state.notifications);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  if (mobile) {
    return (
      <List disablePadding>
        {notifications.map((notification) => {
          const Icon = iconsMap[notification.type];

          return (
            <ListItem
              component={RouterLink}
              divider
              key={notification.id}
              to={notification.link}
            >
              <ListItemAvatar>
                <Avatar
                  className={classes.icon}
                >
                  <SvgIcon fontSize="small">
                    <Icon />
                  </SvgIcon>
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={notification.title}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  return (
    <>
      <Box
        style={{ margin: 5 }}
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Link
          className={classes.link}
          color="textSecondary"
          underline="none"
          variant="body2"
        >
          Products <AiOutlineDown />
        </Link>
      </Box>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <List disablePadding>
          {notifications.map((notification) => {
            const Icon = iconsMap[notification.type];

            return (
              <ListItem
                component={RouterLink}
                divider
                key={notification.id}
                to={notification.link}
              >
                <ListItemAvatar>
                  <Avatar
                    className={classes.icon}
                  >
                    <SvgIcon fontSize="small">
                      <Icon />
                    </SvgIcon>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={notification.title}
                />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
};

export default APICatalogue;
