import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
  colors,
  Card,
  CardContent,
  CardActionArea,
  Avatar,
  SvgIcon,
} from '@material-ui/core';
import {
  Grid as GridIcon,
  BarChart2
} from 'react-feather';

import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#e0ecf8',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 70,
    width: 70
  },
  avatar: {
    backgroundColor: colors.red[600]
  },
  stepper: {
    backgroundColor: 'transparent'
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const FreeTool = ({ className, ...rest }) => {
  const classes = useStyles();


  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div id="api-catalogue">

        <Container maxWidth="lg">

    
        
          <Box mt={8}>
            <Grid
              container
              spacing={3}
            >
       
              <Grid
                item
                xs={12}
                md={6}
              >
                <Card>
                  <CardActionArea
                    component={RouterLink}
                    to={{ pathname: "https://www.ai-qrcodes-generator.com" }}
                    target='_blank' >
                    
                    <CardContent>

                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                      >
                        <Avatar
                          className={classes.icon}
                        >
                          <SvgIcon fontSize="large">
                            <GridIcon />
                          </SvgIcon>
                        </Avatar>

                        <Typography
                          variant="h2"
                          gutterBottom
                          color="textPrimary"
                        > Free QR Code Generator
                        </Typography>
                        
                        <Divider />

                        <Typography
                          variant="body1"
                          gutterBottom
                          color="textPrimary"
                        >
                          Need a quick, secure way to generate QR Codes? Look no further than Free QR Code Generator! Perfect solution if you want to create high-quality codes with your own branding, data, and design. With our easy-to-use platform, you can generate custom codes in just a few seconds! Take the hassle out of creating QR Codes and get started today with Free QR Code Generator!
                        </Typography>
                      
                      </Box>
                    </CardContent>

                  </CardActionArea>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
              >
                <Card>
                  <CardActionArea
                    component={RouterLink}
                    to={{ pathname: "https://ai.qrcodes.apitier.com/register" }}
                    target='_blank' >
                    <CardContent>
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection="column"
                        textAlign="center"
                      >
                        <Avatar
                          className={classes.icon}
                        >
                          <SvgIcon fontSize="large">
                            <BarChart2 />
                          </SvgIcon>
                        </Avatar>
                        <Typography
                          variant="h2"
                          gutterBottom
                          color="textPrimary"
                        > Get QRCode Statistics
                        </Typography>

                        <Divider />

                        <Typography
                          variant="body1"
                          gutterBottom
                          color="textPrimary"
                        >
                          Monitor scans, locations, devices, and scan times to optimize your campaigns and make data-driven decisions.Elevate your marketing game with our cutting-edge QR code solution - Get Dynamic QRCodes in seconds!! Harness the potential of dynamic QR codes to engage your audience and drive meaningful results. Try it now!
                          
                          </Typography>

                      </Box>
                    </CardContent>

                  </CardActionArea>
                </Card>

              </Grid>

            </Grid>
          </Box>


        </Container>
      </div>
    </div >
  );
};

FreeTool.propTypes = {
  className: PropTypes.string
};

export default FreeTool;
