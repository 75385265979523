import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Link,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(61, 56, 165)',
    paddingTop: 44,
    paddingBottom: 60
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  },
  footerList: {
    color: 'white',
    display: 'block',
    lineHeight: 3,
    // fontSize:'0.9em'
  }
}));

const Footer = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container >
        <Box mt={1}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Box ml={2}>
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    style={{ color: 'white' }}
                  >
                    Copyright © 2024
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    style={{ color: 'white', fontSize: '0.9em' }}
                  >
                    Registered in England & Wales, number 07611451.
                    <br /><br />
                    Authorised Royal Mail PAF® Solutions Provider.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
            >
              <Box display="flex">
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                    style={{ color: 'white' }}
                  >
                    Products
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    className={classes.footerList}
                    style={{ textDecoration: 'none' }}
                    component={HashLink}
                    to={'/api-products#verification-api'}
                  >
                    VERIFICATION API
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    className={classes.footerList}
                    style={{ textDecoration: 'none' }}
                    component={HashLink}
                    to={'/api-products#uk-postcode-api'}
                  >
                    UK POSTCODE API
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    className={classes.footerList}
                    style={{ textDecoration: 'none' }}
                    component={HashLink}
                    to={'/api-products#web-conversion-api'}
                  >
                    WEB CONVERSION
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    className={classes.footerList}
                    style={{ textDecoration: 'none' }}
                    component={HashLink}
                    to={'/api-products#data-conversion-api'}
                  >
                    DATA CONVERSION
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    className={classes.footerList}
                    style={{ textDecoration: 'none' }}
                    component={HashLink}
                    to={'/api-products#india-pincode-api'}
                  >
                    INDIA PinCode API
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    className={classes.footerList}
                    style={{ textDecoration: 'none' }}
                    component={HashLink}
                    to={'/api-products#barcode-api'}
                  >
                    QRCODE API
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
            >
              <Box display="flex">
                <Box ml={1}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                    style={{ color: 'white' }}
                  >
                    Company
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                    style={{ color: 'white', display: 'block', lineHeight: 3, fontSize: '0.9em' }}
                    spacing={3}
                    className={classes.footerList}
                  >
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      className={classes.footerList}
                      component={RouterLink}
                      style={{ textDecoration: 'none' }}
                      to="/legal/terms-of-service"
                    >
                      Terms of Service
                    </Typography>
                    <Typography
                      gutterBottom
                      color="textPrimary"
                      className={classes.footerList}
                      component={RouterLink}
                      style={{ textDecoration: 'none' }}
                      to="/legal/privacy-policy"
                    >
                      Privacy Policy
                    </Typography>

                    <Typography>
                      <Link
                        gutterBottom
                        color="textPrimary"
                        className={classes.footerList}
                        component={RouterLink}
                        style={{ textDecoration: 'none', fontSize: '9' }}
                        target="_blank"
                        to={{ pathname: "https://apitier-portal.s3.eu-west-1.amazonaws.com/royal-mail-end-user-licence.pdf" }}
                      >
                        Royal Mail User Licence
                      </Link>
                    </Typography>

                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
            >
         
              <Box display="flex">
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                    style={{ color: 'white' }}
                    spacing={3}
                  >
                    Free Tools
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                    style={{ color: 'white', textDecoration: 'none' }}
                    spacing={3}
                    component={RouterLink}
                    to={{ pathname: "https://www.apitier.com/free-tools/artistic-qrcode-generator" }}
                    target="_blank"
                  >

                  Artistic QR Code Generator
                  </Typography>

                  

                  <Typography
                    gutterBottom
                    color="textPrimary"
                    className={classes.footerList}
                    component={RouterLink}
                    style={{ textDecoration: 'none' }}
                    to={{ pathname: "https://www.apitier.com/tools/free-barcode-generator" }}
                    target="_blank"
                  >
                    BarCode Generator
                  </Typography>          

                  <Typography
                    gutterBottom
                    variant="body1"
                    color="textPrimary"
                    className={classes.footerList}
                    component={RouterLink}
                    style={{ textDecoration: 'none' }}
                    to={{ pathname: "https://www.apitier.com/tools/free-qrcode-generator" }}
                    target="_blank"
                  >

                    QR Code Generator
                  </Typography>        

                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                    style={{ color: 'white', textDecoration: 'none' }}
                    spacing={3}
                    component={RouterLink}
                    to={{ pathname: "https://www.apitier.com/resource" }}
                    target="_blank"
                  >

                    Resources
                  </Typography>        
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={2}
            >
                <Box display="flex">
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                    style={{ color: 'white' }}
                    spacing={3}
                  >
                    Developers
                  </Typography>
  
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.footerList}
                    style={{ color: 'white', textDecoration: 'none' }}
                    spacing={3}
                    component={RouterLink}
                    to={{ pathname: "https://www.apitier.com/pricing-plans" }}
                    target="_blank"
                  >

                    Pricing Plans
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.footerList}
                    style={{ color: 'white', textDecoration: 'none' }}
                    spacing={3}
                    component={RouterLink}
                    to={{ pathname: "https://www.apitier.com/api-catalogue" }}
                    target="_blank"
                  >

                  API Catalogue
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.footerList}
                    style={{ color: 'white', textDecoration: 'none' }}
                    spacing={3}
                    component={RouterLink}
                    to={{ pathname: "https://docs.apitier.com" }}
                    target="_blank"
                  >

                    Documentation
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    className={classes.footerList}
                    component={RouterLink}
                    spacing={3}
                    style={{ textDecoration: 'none' }}
                    to={{ pathname: "https://github.com/apitier" }}
                    target="_blank"
                  >
                    GitHub
                  </Typography>                  
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                    spacing={3}
                    style={{ color: 'white', textDecoration: 'none' }}
                    component={RouterLink}
                    to={{ pathname: "https://blog.apitier.com" }}
                    target="_blank"
                  >
                    Blog
                  </Typography>
                  <Typography
                    gutterBottom
                    color="textPrimary"
                    className={classes.footerList}
                    component={RouterLink}
                    spacing={3}
                    style={{ textDecoration: 'none' }}
                    to={{ pathname: "https://status.apitier.com" }}
                    target="_blank"
                  >
                    API Status
                  </Typography>                  
                </Box>
              </Box>

           
              <Box display="flex">
                <Box ml={2}>
                <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                    style={{ color: 'white' }}
                  >
                  </Typography>
                  <Typography
                    variant="h4"
                    gutterBottom
                    spacing={3}
                    color="textPrimary"
                    style={{ color: 'white' }}
                  >
                    Get in touch
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                    style={{ color: 'white' }}
                  >
                    <a href="mailto:hello@apitier.com" style={{ color: 'white' }}>
                      <div style={{ display: 'flex' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                        &nbsp;hello@apitier.com
                      </div>
                    </a>
                  </Typography>
                </Box>
              </Box>

            </Grid>

          </Grid>
        </Box>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;