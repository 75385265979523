import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Avatar,
  SvgIcon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  StepConnector,
  withStyles,
  Step,
  StepLabel,
  Stepper,
  colors,
} from '@material-ui/core';
import {
  MapPin,
  Home,
  AtSign,
  Trello,
  Map,
  Phone,
  ShoppingBag,
  File,
  FileText,
  FilePlus,
  FileMinus,
  Code,
  Monitor,
  Database,
  Search,
  Chrome,
  BarChart2
} from 'react-feather';
import { Grid as GridIcon} from 'react-feather';

import { Link as RouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


const datasteps = [
  {
    label: 'JSON To XML',
    icon: FilePlus
  },
  {
    label: 'XML To JSON',
    icon: FileMinus
  },
  {
    label: 'JSON To CSV',
    icon: FilePlus
  },
  {
    label: 'CSV To JSON',
    icon: FileMinus
  },
  {
    label: 'CSV To XML',
    icon: FilePlus
  }
];


const CustomStepConnector = withStyles((theme) => ({
  vertical: {
    marginLeft: 19,
    padding: 0,
  },
  line: {
    borderColor: theme.palette.divider
  }
}))(StepConnector);

const useCustomStepIconStyles = makeStyles((theme) => ({
  root: {},
  active: {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[10],
    color: theme.palette.secondary.contrastText
  },
  completed: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const CustomStepIcon = ({ active, completed, icon, steps }) => {
  const classes = useCustomStepIconStyles();

  const Icon = steps[icon - 1].icon;

  return (
    <Avatar
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      <Icon size="20" />
    </Avatar>
  );
};

CustomStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.number.isRequired,
  steps: PropTypes.array.isRequired
};


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  avatar: {
    backgroundColor: colors.red[600]
  },
  stepper: {
    backgroundColor: 'transparent'
  },
}));

const Products = ({ className, ...rest }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(4);

  const CustomStepIcon = ({ active, completed, icon }) => {
    const classes = useCustomStepIconStyles();

    const Icon = datasteps[icon - 1].icon;

    return (
      <Avatar
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed
        })}
      >
        <Icon size="20" />
      </Avatar>
    );
  };

  CustomStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.number.isRequired
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <div id="product-list">
        <Container maxWidth="lg">

          <Typography
            variant="h1"
            align="center"
            color="textPrimary"
          >
            API Products
          </Typography>

          <Box my={3}>
            <Divider />
          </Box>

          <Grid
            container
            spacing={3}
            component="dl"
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography
                variant="h3"
                component={HashLink}
                to={'#verification-api'}
                color="secondary"
                id="verification-api"
              >
                VERIFICATION API
              </Typography>

              <Box mt={6}>
                <List>

                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/validate-email-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <AtSign />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Validate Email
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Validates single email or bulk email addresses.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>


                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/validate-phone-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Phone />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Validate Phone
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Validate national and international phone numbers and get location information about phone number.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/validate-vat-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <ShoppingBag />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Validate VAT
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Validate VAT numbers for verification and get company information, including address, returned for each VAT number.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/validate-useragent-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Chrome />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            User Agent

                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Parses User Agent to detect Browser, Engine, OS, CPU, and Device type/model,etc.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                </List>
              </Box>

            </Grid>


            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography
                variant="h3"
                color="secondary"
                id="uk-postcode-api"
                component={HashLink}
                to={'#uk-postcode-api'}
              >
                UK POSTCODE API
              </Typography>

              <Box mt={6}>
                <List >
                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/postcode-lookup-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Home/>
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            PostCode Lookup
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Gets addresses for given PostCode.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/address-autosuggest-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Map />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Address AutoSuggest
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Suggest addresses for given search term.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/address-lookup-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <MapPin />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Address Lookup
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Gets addresses for given search term.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/address-lookup-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Trello />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            UDPRN Lookup
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Gets addresses for given UDPRN.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>

            </Grid>


          </Grid>

          <Box my={3}>
          </Box>

          <Grid
            container
            spacing={3}
            component="dl"
          >

            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography
                variant="h3"
                color="secondary"
                id="data-conversion-api"
                component={HashLink}
                to={'#data-conversion-api'}
              >
                CONVERSION API
              </Typography>

              <Box mt={6}>
                <List >
                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/data-conversion-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Database />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Data Conversion

                          </Typography>
                          
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Converts data from the one format to another.
                          </Typography>
                          <Stepper
                            activeStep={activeStep}
                            className={classes.stepper}
                            connector={<CustomStepConnector />}
                            orientation="vertical"
                          >
                            {datasteps.map((step) => (
                              <Step key={step.label}>
                                <StepLabel StepIconComponent={CustomStepIcon}>
                                  {step.label}
                                </StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                        </React.Fragment>
                      }
                    />


                  </ListItem>
                  
                </List>
              </Box>

            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography
                variant="h3"
                color="secondary"
                id="web-conversion-api"
                component={HashLink}
                to={'#web-conversion-api'}
              >
                WEB CONVERSION API
              </Typography>

              <Box mt={6}>
                <List >
                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/screenshot-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Monitor   />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            ScreenShot API
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Take ScreenShot of web url or html content.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/web-to-pdf-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <File />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Web To PDF API
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Extract web content as PDF from web url or html content.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/web-scrape-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Code />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Web Scrape API
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Extract html code & data from a Web URL.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/web-to-text-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <FileText />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Web To Text API
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Extract web content as text  from a url or html content.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </List>
              </Box>

            </Grid>

          </Grid>

         <Grid
            container
            spacing={3}
            component="dl"
          >

            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography
                variant="h3"
                color="secondary"
                id="india-pincode-api"
                component={HashLink}
                to={'#india-pincode-api'}
              >
                INDIA PINCODE API
              </Typography>

              <Box mt={6}>
              <List >
                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/india-pincode-api"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <Search   />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            PinCode Lookup
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            JSON API to query India PinCode and Places.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                 
                </List>
              </Box>

            </Grid>

            <Grid
              item
              xs={12}
              md={6}
            >
              <Typography
                variant="h3"
                color="secondary"
                id="barcode-api"
                component={HashLink}
                to={'#barcode-api'}
              >
                QRCODE API
              </Typography>

              <Box mt={6}>
                <List >
                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/barcode-api"
                    // to="/signin"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <BarChart2   />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Generate BarCode
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Versatile barcode generator API.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>

                  <ListItem
                    component={RouterLink}
                    divider
                    to="/api-catalogue/qrcode-api"
                    // to="/signin"
                    alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        className={classes.icon}
                      >
                        <SvgIcon fontSize="small">
                          <GridIcon />
                        </SvgIcon>
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <Typography
                            variant="h3"
                            color="textPrimary"
                          >
                            Generate QR Code
                          </Typography>
                        </React.Fragment>
                      }
                      secondary={
                        <React.Fragment>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            Rich Featured QR Code generator API.
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>  
                </List>
              </Box>

            </Grid>

          </Grid>

        </Container>
      </div>
    </div>
  );
};

Products.propTypes = {
  className: PropTypes.string
};

export default Products;
