import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Catalogue from './Catalogue'
const useStyles = makeStyles(() => ({
  root: {}
}));

const APICatalogueView = () => {
  const classes = useStyles();

  return (

    <Page
      className={classes.root}
      title="Scalable & Restful API Platform with Comprehensive API Catalogue | APITier"
      description="Build powerful, secure, and scalable applications with our comprehensive API platform. "
      canonicalURL="https://www.apitier.com/api-catalogue"
    >

      <Catalogue />

    </Page>
  );
};

export default APICatalogueView;
