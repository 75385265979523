export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const socialLoginConfig = {  
  google_app_id: process.env.REACT_APP_GG_APP_ID,
  github_app_id: process.env.REACT_APP_GITHUB_APP_ID,
  github_app_secret: process.env.REACT_APP_GITHUB_APP_SECRET
};


export const dashboardApiConfig = {
  dashboard_api_url: process.env.REACT_APP_DASHBOARD_API_URL,
  dashboard_api_key: process.env.REACT_APP_DASHBOARD_API_KEY,
  dashboard_postcode_api_url: process.env.REACT_APP_POSTCODE_API_URL,
  dashboard_validate_email_api_url: process.env.REACT_APP_VALIDATE_EMAIL_API_URL,
  dashboard_validate_phone_api_url: process.env.REACT_APP_VALIDATE_PHONE_API_URL,
  dashboard_validate_vat_api_url: process.env.REACT_APP_VALIDATE_VAT_API_URL,
  dashboard_validate_ua_api_url: process.env.REACT_APP_VALIDATE_UA_API_URL,
  dashboard_web_conversion_api_url: process.env.REACT_APP_WEB_CONVERSION_API_URL,
  dashboard_data_conversion_api_url: process.env.REACT_APP_DATA_CONVERSION_API_URL,
  dashboard_india_pincode_api_url: process.env.REACT_APP_INDIA_PINCODE_API_URL,
  dashboard_barcode_api_url: process.env.REACT_APP_BARCODE_API_URL,
  dashboard_ai_qrcode_api_url: process.env.REACT_APP_AI_QRCODE_API_URL,

  dashboard_postcode_api_usage_plan: process.env.REACT_APP_POSTCODE_API_USAGE_PLAN,
  dashboard_validate_email_api_usage_plan: process.env.REACT_APP_VALIDATE_EMAIL_API_USAGE_PLAN,
  dashboard_validate_phone_api_usage_plan: process.env.REACT_APP_VALIDATE_PHONE_API_USAGE_PLAN,
  dashboard_validate_vat_api_usage_plan: process.env.REACT_APP_VALIDATE_VAT_API_USAGE_PLAN,
  dashboard_validate_ua_api_usage_plan: process.env.REACT_APP_VALIDATE_UA_API_USAGE_PLAN,
  dashboard_web_conversion_api_usage_plan: process.env.REACT_APP_WEB_CONVERSION_API_USAGE_PLAN,
  dashboard_data_conversion_api_usage_plan: process.env.REACT_APP_DATA_CONVERSION_API_USAGE_PLAN,
  dashboard_india_pincode_api_usage_plan: process.env.REACT_APP_INDIA_PINCODE_API_USAGE_PLAN,
  dashboard_barcode_api_usage_plan: process.env.REACT_APP_BARCODE_API_USAGE_PLAN,
  dashboard_ai_qrcode_api_usage_plan: process.env.REACT_APP_AI_QRCODE_API_USAGE_PLAN,

  dashboard_postcode_api_demo_key: process.env.REACT_APP_POSTCODE_API_DEMO_KEY,
  dashboard_validate_email_api_demo_key: process.env.REACT_APP_VALIDATE_EMAIL_API_DEMO_KEY,
  dashboard_validate_phone_api_demo_key: process.env.REACT_APP_VALIDATE_PHONE_API_DEMO_KEY,
  dashboard_validate_vat_api_demo_key: process.env.REACT_APP_VALIDATE_VAT_API_DEMO_KEY,
  dashboard_validate_ua_api_demo_key: process.env.REACT_APP_VALIDATE_UA_API_DEMO_KEY,
  dashboard_web_conversion_api_demo_key: process.env.REACT_APP_WEB_CONVERSION_API_DEMO_KEY,
  dashboard_data_conversion_api_demo_key: process.env.REACT_APP_DATA_CONVERSION_API_DEMO_KEY,
  dashboard_india_pincode_api_demo_key: process.env.REACT_APP_INDIA_PINCODE_API_DEMO_KEY,
  dashboard_barcode_api_demo_key: process.env.REACT_APP_BARCODE_API_DEMO_KEY,
  dashboard_ai_qrcode_api_demo_key: process.env.REACT_APP_AI_QRCODE_API_DEMO_KEY,

  stripe_apiKey: process.env.REACT_APP_STRIPE_API_KEY,
  startup_pricing_table_id: process.env.REACT_APP_STARTUP_PRICING_TABLE_ID,
  sme_pricing_table_id: process.env.REACT_APP_SME_PRICING_TABLE_ID,
  enterprise_pricing_table_id: process.env.REACT_APP_ENTERPRISE_PRICING_TABLE_ID
};
