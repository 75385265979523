import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  DollarSign as DollarSignIcon,
  CreditCard as CreditCardIcon,
  FastForward as FastForwardIcon,
  Activity as ActivityIcon,
  Clock as ClockIcon,
  GitMerge as GitMergeIcon
} from 'react-feather';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 78,
    paddingBottom: 78
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  }
}));

const BusinessBenefits = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="h4"
          color="secondary"
          align="center"
        >
          HOW TO EXPEDITE APPLICATION DEVELOPMENT?
        </Typography>
        <Box my={3}>
          <spacing />
        </Box>
        <Typography
          variant="h2"
          align="center"
          color="textPrimary"
        >
         Why You Should Use APITier APIs for Your Business? 
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <DollarSignIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Save You Time
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
API’s can automate a lot of the tedious tasks that take up your time, such as data entry and customer management.
 This frees you up to focus on more important things, like growing your business.                    </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <CreditCardIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Improve Efficiency
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
API’s can help you streamline your processes and make your operation more efficient. This means that you can get
more work done in less time, and with fewer resources.

                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <FastForwardIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Reduce Costs
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    By automating tasks and improving efficiency, APIs can help you reduce your operating costs. This can free up
more money to invest in other areas of your business, or simply increase your profits.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <ActivityIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Improve Customer Service
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    Improve your customer service.
 This can lead to more repeat customers and higher levels of customer satisfaction.
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <ClockIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Streamline Your Operations
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
 With APIs, you can streamline your operations by automating tasks and integrating different systems. This can save
 you time and money, and help you scale your business more effectively.                                      </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <Avatar className={classes.avatar}>
                  <GitMergeIcon />
                </Avatar>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    Greater Scalability
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    You will be able to quickly scale up or down depending on your needs. This makes it easier for
businesses of any size to take advantage of the powerful features offered by APITier’s APIs.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

BusinessBenefits.propTypes = {
  className: PropTypes.string
};

export default BusinessBenefits;