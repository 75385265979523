import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography, 
  makeStyles,
  Button
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.down('md')]: {
      paddingTop: 30,
      paddingBottom: 30
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();
 

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={5}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
            
              <Typography
                variant="h1"
                color="textPrimary"
              >
               APITier - Highly Scalable APIs
              </Typography>
              <Box mt={3}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
APITier is the perfect solution for individual developers, startups, SMEs, and large enterprises who want to save valuable time and energy when building and maintaining software products.                 </Typography>
<br/>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >

Our highly scalable API platform offers a suite of APIs, including verification, web conversion, data conversion, and UK postcode APIs. These essential core building blocks make it easy to enrich user experience, perform address checks and email validation, scrape web content and data quickly and easily. 
</Typography>
<br/>
                <Typography
                  variant="body1"
                  color="secondary"
                >
Your account comes with a free trial API KEY to access all APITier APIs.                  
                  </Typography>
              </Box>

              {/* <Box mt={3}>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      variant="h2"
                      color="secondary"
                    >
                      API
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Integration Sinppets
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h2"
                      color="secondary"
                    >
                      UX
                    </Typography>
                    <Typography
                      variant="overline"
                      color="textSecondary"
                    >
                      Complete Flows
                    </Typography>
                  </Grid>
                </Grid>
              </Box> */}

              <Box
                mt={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  color="secondary"
                  component={RouterLink}
                  to={{ pathname: "/api-catalogue" }}
                  variant="contained"
                  style={{ right: 60 }}
                >
                  API Catalogue
                </Button>

                <Button
                  color="secondary"
                  component={RouterLink}
                  to={{ pathname: "/signin" }}
                  variant="contained"
                  style={{ right: 10 }}
                >
                  Get Started
                </Button>
       
                
              </Box>

            </Box>
          </Grid>
          
          <Grid
            item
            xs={12}
            md={7}
          >
            <Box position="relative">
              <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div>
              <div className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/home/apitier_dashboard_dark.png"
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
