import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Box
} from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 48,
    paddingBottom: 48
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  }
}));

const Recommendation = ({ className, ...rest }) => {
  const classes = useStyles();
  const overview = {
    income: '854,355.00',
    expanses: '373,250.50',
    profit: '123,532.00',
    subscriptions: '26,000'
  };

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">

        <Typography
          component="p"
          variant="h4"
          color="secondary"
          align="center"
        >
          WHO IS USING APITIER API?
        </Typography>
        <Box my={3}>
          <spacing />
        </Box>
        <Typography
          variant="h2"
          align="center"
          color="textPrimary"
        >
          Recommended By
        </Typography>

        <Grid
          alignItems="center"
          container
          justify="space-between"
        >
          <Grid
            className={classes.item}
            item
            md={3}
            sm={6}
            xs={12}
          >
            <div className={classes.valueContainer}>
              <a 
              href="https://www.capterra.com/p/253584/Validate-Email-API" 
              target="_blank" >

                <img width="250" heigh="250"
                  id="capterra"
                  src="/static/listing/capterra-logo.jpg"
                  alt="Capterra" />
              </a>
            </div>
          </Grid>

          <Grid
            className={classes.item}
            item
            md={3}
            sm={6}
            xs={12}
          >
            <div className={classes.valueContainer}>
              <a href="https://www.getapp.com/it-communications-software/a/validate-email-api" target="_blank">
                <img width="250" heigh="250"
                  id="getapp"
                  src="/static/listing/getapp-logo.jpg"
                  alt="getapp" />
              </a>
            </div>
          </Grid>

          <Grid
            className={classes.item}
            item
            md={3}
            sm={6}
            xs={12}
          >
            <div className={classes.valueContainer}>
              <a href="https://www.softwareadvice.com/email-verification/validate-email-api-profile" target="_blank">
                <img width="250" heigh="250"
                  id="software-advice"
                  src="/static/listing/software-advice-logo.jpg"
                  alt="software-advice" />
              </a>
            </div>
          </Grid>

          <Grid
            className={classes.item}
            item
            md={3}
            sm={6}
            xs={12}
          >
            <a href="https://www.g2.com/products/highly-scalable-cloud-apis-with-affordable-pay-as-you-go-pricing-plans/reviews" target="_blank">
                <img width="100" heigh="100"
                  id="g2"
                  src="/static/listing/g2-logo.jpg"
                  alt="g2" />
              </a>
          </Grid>

        </Grid>
      </Container>
    </div>
  );
};

Recommendation.propTypes = {
  className: PropTypes.string
};

export default Recommendation;
